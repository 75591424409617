<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>Your {{ plan_name }} workout plan is ready!</h2>

    <div style="display: flex; justify-content: center; align-items: center">
      <div class="chart">
        <apexchart type="line" :options="chartOptions" :series="chartSeries" />
      </div>
    </div>
    <div style="display: flex; justify-content: center" class="sticky-content">
      <p class="disclaimer">This chart is for illustrative purposes only.</p>
    </div>

    <div class="sticky-button">
      <BaseButton @click="saveAndNavigate('continue', '', '/Plan-Offer')">
        CONTINUE
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import axios from "axios";
import { onMounted, ref } from "vue";
import BaseButton from "../components/BaseButton.vue";

//https://apexcharts.com/vue-chart-demos/line-charts/data-labels/

// Define your chart options
const chartOptions = ref({
  chart: {
    type: "line",
    dropShadow: {
      enabled: true,
      color: "#000",
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2,
    },
    toolbar: {
      show: false,
    },
  },

  annotations: {
    points: [
      {
        x: "Now",
        y: 90,
        marker: {
          size: 0,
          fillColor: "#fff",
          strokeColor: "fff",
          radius: 0,
          cssClass: "apexcharts-custom-class",
        },
      },
      {
        x: "Event",
        y: 72,
        marker: {
          size: 8,
          fillColor: "#fff",
          strokeColor: "#ccc",
          strokeOpacity: 0.1,
          strokeWidth: 3,
          radius: 4,
          cssClass: "apexcharts-custom-class",
        },
        label: {
          borderColor: "#4263eb",
          offsetY: -20,
          offsetX: -20,
          style: {
            color: "#fff",
            background: "#4263eb",
          },

          text: "Goal Weight",
        },
      },
    ],
    xaxis: [],
  },

  colors: ["#4263eb", "#545454"],
  dataLabels: {
    enabled: false,
  },
  stroke: { width: 9, curve: "smooth" },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      gradientToColors: ["#FF0000", "#FFFF00", "#00FF00"],
      shadeIntensity: 1,
      type: "vertical",
      opacityFrom: 0.9,
      opacityTo: 0.9,
      stops: [10, 50, 100],
    },
  },
  title: {
    text: "Your Weight",
    align: "left",
  },
  grid: {
    borderColor: "#e7e7e7",
    row: {},
  },
  markers: {
    size: 0,
  },
  xaxis: {
    categories: [],
    title: {
      text: "Weeks",
    },
  },
  yaxis: {
    title: {
      text: "Weight",
    },
    min: 60,
    max: 120,
  },
});

// Define the series data
const chartSeries = ref([
  {
    name: "",
    data: [],
  },
]);

const plan_name = ref("");

guardNoCookie();
useProgress().set(31);

axios
  .get(`/api/VarStore/GetChartData?sid=${store.state.uid}`)
  .then((response) => {
    const data = response.data;
    chartSeries.value[0].data = data.values;

    chartOptions.value = {
      ...chartOptions.value,
      xaxis: { ...chartOptions.value.xaxis, categories: data.labels },
    };

    chartOptions.value.yaxis.min = data.min;
    chartOptions.value.yaxis.max = data.max;

    chartOptions.value.annotations.points[0].y = data.weight;
    chartOptions.value.annotations.points[1].y = data.goal;
    chartOptions.value.annotations.points[1].x =
      chartOptions.value.xaxis.categories[
        chartOptions.value.xaxis.categories.length - 1
      ];
    chartOptions.value.xaxis.title.text = data.period;

    plan_name.value = data.plan_name;
    chartOptions.value.annotations.xaxis.push({
      x: isNoEvent.value
        ? ""
        : data.period === "Weeks"
        ? convertDateToWeekLabel(data.event_date)
        : convertDateToMonthLabel(data.event_date),
      borderColor: "#4263eb",
      strokeDashArray: 4,
      label: {
        orientation: "horizontal",
        offsetY: 20,
        borderColor: "#4263eb",
        style: {
          color: "#fff",
          background: "#4263eb",
        },
        text: `${data.spec_occas} - ${data.event_weight}${data.goal_unit}`,
      },
    });

    function convertDateToWeekLabel(date) {
      const startDate = new Date();
      const eventDate = new Date(date);

      // Calculate the difference in time
      const timeDiff = eventDate - startDate;

      // Calculate the number of weeks
      const weeksDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24 * 7));

      return `Week ${weeksDiff}`;
    }
    function convertDateToMonthLabel(date) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const [month, ,] = date.split("/");
      return months[parseInt(month - 1)];
    }
  })
  .catch((error) => {
    console.error("Došlo k chybě při načítání dat:", error);
  });

onMounted(async () => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
</script>

<style scoped>
.chart {
  width: 100%;
  max-width: 600px;
  max-height: 400px;
  height: 100%;
}

.disclaimer {
  max-width: 600px;
  font-size: 12px;
  text-align: center;
  margin-top: 0px;
  color: #6c757d;
}
</style>
