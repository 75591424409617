<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>How do you feel between meals?</h2>
    <p>
      What's your energy like at different times of the day? Select the most
      appropriate option
    </p>
        <div class="options">
      <BaseOption
        text="I get sleepy when I'm hungry"
        :img-src="require('../assets/images/sleep.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('feel_b_meals','Sleepy')"
        @click="saveAndNavigate('feel_b_meals', 'Sleepy', '/How-Much-Sleep')"
      />
      <BaseOption
        text="I am tired after I eat"
        :img-src="require('../assets/images/yawn.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('feel_b_meals','Tired')"
        @click="saveAndNavigate('feel_b_meals', 'Tired', '/How-Much-Sleep')"
      />
      <BaseOption
        text="I am always feeling energized"
        :img-src="require('../assets/images/wink.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('feel_b_meals','Energized')"
        @click="saveAndNavigate('feel_b_meals', 'Energized', '/How-Much-Sleep')"
      />
      </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { onMounted } from "vue";
import BaseOption from "../components/BaseOption.vue";
import { useSavedData } from "../store/savedDataStore";
guardNoCookie();
useProgress().set(15);
const { isSelected } = useSavedData();

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
</script>
<style scoped>
.options {
  margin: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>