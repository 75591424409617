import { router } from "@/router";
import store from "@/store";
import { getCookie } from "@/utils/getCookie";

/** Guards that a cookie exists. If not redirects back to start. */
export function guardNoCookie() {
  const cookieName = "MID";
  const identifier = getCookie(cookieName);
  if (!identifier) {
    console.log("No Cookie - redirect to start");
    router.replace({ path: "/" });
  }

  store.commit("setUID", identifier);
}
