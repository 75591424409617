<template>
  <div id="step" class="survey-step">
    <h2>What do you want to achieve?</h2>
    <p>You can select multiple goals.</p>
    <div class="sticky-content">
      <div v-for="(answer, index) in ANSWERS" :key="index" class="selection">
        <BaseOption
          :id="answer.value"
          :text="answer.text"
          :img-src="answer.img"
          :img-alt="answer.text"
          :is-selected="answer.selected"
          @click="answer.selected = !answer.selected"
        />
      </div>
    </div>
    <div class="sticky-button">
      <BaseButton
        :disabled="!isAnyAnswerSelected"
        @click="
          saveAndNavigate('achieve_goals', dataToSend, sNavigate)
        "
      >
        CONTINUE
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import BaseButton from "@/components/BaseButton.vue";
import BaseOption from "@/components/BaseOption.vue";
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { ref, computed, onMounted, reactive } from "vue";
import { useSavedData } from "../store/savedDataStore";

  const appVer = ref(process.env.VUE_APP_VER);

  let sNavigate = '';
  if (appVer.value === "YOGA") {
    sNavigate = '/Familiar-With-Yoga';
  } else if (appVer.value === "CALI") {
    sNavigate = '/Familiar-With-Calisthenics';
}
 // console.log('APP: ' + appVer.value);
 // console.log('NAV: '+ sNavigate);

guardNoCookie();
useProgress().set(3);

const isFemale = computed(() => store.state.gender === "female");

function isSelected(value) {
  const achieveGoals = useSavedData().data.value.achieve_goals;
  if (!achieveGoals) return false;
  const goalsArray = achieveGoals.split(",");
  return goalsArray.includes(value);
}

const ANSWERS = reactive([
  {
    text: "Lose weight",
    value: "Weight",
    img: isFemale.value
      ? require("../assets/images/loseW.png")
      : require("../assets/images/loseWeight.png"),
    selected: false,
  },
  {
    text: "Improve heart health",
    value: "Heart",
    img: isFemale.value
      ? require("../assets/images/hearthW.png")
      : require("../assets/images/hearthHealth.png"),
    selected: false,
  },
  {
    text: "Get firm and toned",
    value: "Toned",
    img: isFemale.value
      ? require("../assets/images/fitW.png")
      : require("../assets/images/firmToned.png"),
    selected: false,
  },
  {
    text: "Relieve stress",
    value: "Stress",
    img: isFemale.value
      ? require("../assets/images/stressW.png")
      : require("../assets/images/relieveStress.png"),
    selected: false,
  },
]);

const isAnyAnswerSelected = computed(() => {
  return ANSWERS.some((answer) => answer.selected);
});

const dataToSend = computed(() =>
  ANSWERS.filter((answer) => answer.selected)
    .map((answer) => answer.value)
    .join(",")
);

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
  ANSWERS.forEach((answer) => {
    answer.selected = isSelected(answer.value);
  });
});
</script>
<style scoped>
.selection {
  display: flex;
  justify-content: center;
}
</style>
