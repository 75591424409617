<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>Choose the body type you want to have</h2>
           <div class="options">
      <BaseOption
        text="Fit"
        :img-src="isFemale ? require('../assets/images/fittW.png') : require('../assets/images/fit.png')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('body_type_want','Fit')"
        @click="saveAndNavigate('body_type_want', 'Fit', '/Target-Zones')"
      />
      <BaseOption
        text="Cut"
        :img-src="isFemale ? require('../assets/images/cutW.png') : require('../assets/images/cut.png')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('body_type_want','Cut')"
       @click="saveAndNavigate('body_type_want', 'Cut', '/Target-Zones')"
      />
      <BaseOption
        text="Bulk"
        :img-src="isFemale ? require('../assets/images/bulkW.png') : require('../assets/images/bulk.png')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('body_type_want','Bulk')"
        @click="saveAndNavigate('body_type_want', 'Bulk', '/Target-Zones')"
      />
    </div>
  </div>
</template>

<script setup>
import BaseOption from "@/components/BaseOption.vue";
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { computed, onMounted } from "vue";
import { useSavedData } from "../store/savedDataStore";

guardNoCookie();
useProgress().set(6);
const isFemale = computed(() => store.state.gender === "female");
onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
const { isSelected } = useSavedData();
</script>
<style scoped>
.options {
  margin: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>