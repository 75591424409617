import { ref } from "vue";

const token = ref();
export function saveToken(authToken) {
  try {
    sessionStorage.setItem("authToken", authToken);
  } catch (error) {
    //console.error("Error storing item in sessionStorage:", error);
  }
  token.value = authToken;
}

export function getToken() {
  try {
    token.value = sessionStorage.getItem("authToken");
    //sessionStorage.setItem("authToken", authToken);
  } catch (error) {
    //console.error("Error restoring item in sessionStorage:", error);
    token.value = undefined;
  }
  return token.value;
}

export function deleteToken() {
  try {
    sessionStorage.removeItem("authToken");
  } catch (error) {
    //console.error("Error deleting item in sessionStorage:", error);
  }
  token.value = undefined;
}
