<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>Can you touch the floor without bending your knees?</h2>
    <div class="options">
      <BaseOption
        text="I can't"
        :img-src="require('../assets/images/unamused.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('touch_floor','No')"
        @click="saveAndNavigate('touch_floor', 'No', '/Choose-Focus')"
      />
      <BaseOption
        text="I can touch the floor with my fingertips"
        :img-src="require('../assets/images/smiling.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('touch_floor','Finger')"
        @click="saveAndNavigate('touch_floor', 'Finger', '/Choose-Focus')"
      />
      <BaseOption
        text="I can touch the floor with the palms of my hands"
        :img-src="require('../assets/images/glasses.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('touch_floor','Hands')"
        @click="saveAndNavigate('touch_floor', 'Hands', '/Choose-Focus')"
      />
      </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { onMounted } from "vue";
import BaseOption from "../components/BaseOption.vue";
import { useSavedData } from "../store/savedDataStore";
guardNoCookie();
useProgress().set(13);
const { isSelected } = useSavedData();

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
</script>
<style scoped>
.options {
  margin: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>