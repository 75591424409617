<script setup>
import { useProgress } from "../logic/useProgress";
useProgress().set(0);
</script>
<template>
  <div>
    <img src="../../public/logo.png" alt="logo" width="120px" class="logo" />
    <iframe
      id="freshwidget-embedded-form"
      title="Feedback Form"
      class="freshwidget-embedded-form"
      src="https://mindsparklellc.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Help+%26+Support&submitTitle=Create+Ticket&submitThanks=Thank+you+for+your+feedback.+We+will+try+to+resolve+your+ticket+as+soon+as+possible.&searchArea=no"
      scrolling="no"
      width="100%"
      height="800px"
      frameborder="0"
    />
  </div>
</template>

<style scoped></style>
