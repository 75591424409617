<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>How often do you exercise?</h2>
    <div class="options">
      <BaseOption
        text="Never"
        description="Little or no exercise"
        :img-src="require('../assets/images/low.svg')"
        img-alt="Man"
        :img-size="60"
        is-single
        :is-selected="isSelected('activity_level', '1')"
        @click="saveAndNavigate('activity_level', '1', '/Time-Walking')"
      />
      <BaseOption
        text="Several times a month"
        description="Light exercise / sports 1-2 days per week"
        :img-src="require('../assets/images/mid.svg')"
        img-alt="Man"
        :img-size="60"
        is-single
        :is-selected="isSelected('activity_level', '2')"
        @click="saveAndNavigate('activity_level', '2', '/Time-Walking')"
      />
      <BaseOption
        text="Several times a week"
        description="Moderate exercise / sports 3-5 days per week"
        :img-src="require('../assets/images/high.svg')"
        img-alt="Man"
        :img-size="60"
        is-single
        :is-selected="isSelected('activity_level', '3')"
        @click="saveAndNavigate('activity_level', '3', '/Time-Walking')"
      />
      <BaseOption
        text="Almost every day"
        description="Hard exercise / sports 6-7 days per week"
        :img-src="require('../assets/images/full.svg')"
        img-alt="Man"
        :img-size="60"
        is-single
        :is-selected="isSelected('activity_level', '4')"
        @click="saveAndNavigate('activity_level', '4', '/Time-Walking')"
      />
    </div>
  </div>
</template>

<script setup>
import BaseOption from "@/components/BaseOption.vue";
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { onMounted } from "vue";
import { useSavedData } from "../store/savedDataStore";
guardNoCookie();
useProgress().set(9);
const { isSelected } = useSavedData();

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
</script>
<style scoped>
.options {
  margin: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>
