<script setup>
import { useRouter } from "vue-router";
import BaseButton from "../components/BaseButton.vue";
import { useProgress } from "../logic/useProgress";
import { deleteToken } from "../utils/useAuth";

const router = useRouter();

useProgress().set(0);
deleteToken();
</script>

<template>
  <img src="../../public/logo.png" alt="logo" width="120px" class="logo" />
  <h1>Logout</h1>
  <div>
    You've just been logged out of the system. To log back in, click the
    following button.
  </div>
  <div class="btns">
    <a style="width: 300px" @click="router.push('/Login')">
      <BaseButton :width="300">LOG IN</BaseButton>
    </a>
  </div>
</template>
<style scoped>
.image {
  width: 100%;
  max-width: 400px;
}

.stores {
  margin-top: 24px;
  margin-bottom: 12px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.btns {
  margin-top: 32px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
</style>
