<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>Do you follow any of these diets?</h2>
    <div class="options">
      <BaseOption
        text="Vegetarian"
        description="Excludes meat"
        :img-src="require('../assets/images/leafy.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('follow_diet', 'Vegetarian')"
        @click="
          saveAndNavigate('follow_diet', 'Vegetarian', '/How-Tall-Are-You')
        "
      />
      <BaseOption
        text="Vegan"
        description="Excludes all animal products"
        :img-src="require('../assets/images/carrot.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('follow_diet', 'Vegan')"
        @click="saveAndNavigate('follow_diet', 'Vegan', '/How-Tall-Are-You')"
      />
      <BaseOption
        text="Keto"
        description="Low-carb, high-fat"
        :img-src="require('../assets/images/avocado.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('follow_diet', 'Keto')"
        @click="saveAndNavigate('follow_diet', 'Keto', '/How-Tall-Are-You')"
      />
      <BaseOption
        text="Mediterranean"
        description="Rich in plant-based foods"
        :img-src="require('../assets/images/rice.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('follow_diet', 'Mediterranean')"
        @click="
          saveAndNavigate('follow_diet', 'Mediterranean', '/How-Tall-Are-You')
        "
      />
      <BaseOption
        text="No"
        :img-src="require('../assets/images/eyes.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('follow_diet', 'No')"
        @click="saveAndNavigate('follow_diet', 'No', '/How-Tall-Are-You')"
      />
    </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { onMounted } from "vue";
import BaseOption from "../components/BaseOption.vue";
import { useSavedData } from "../store/savedDataStore";
guardNoCookie();
useProgress().set(18);
const { isSelected } = useSavedData();

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
</script>
<style scoped>
.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
