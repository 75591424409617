<template>
  <div id="step" class="survey-step">
    <h2>Choose your focus</h2>
        <div class="options sticky-content">
    <div v-for="(answer, index) in ANSWERS" :key="index">
      <BasePill
        :text="answer.text"
        :is-selected="answer.selected"
        @click="answer.selected = !answer.selected"
      />
    </div>

    </div>
        <div class="sticky-button">
          <BaseButton
          :disabled="!isAnyAnswerSelected" @click="
          saveAndNavigate('your_focus', dataToSend, '/Feel-Between-Meals')"
           >CONTINUE</BaseButton>
           </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { computed, onMounted, reactive } from "vue";
import BaseButton from "../components/BaseButton.vue";
import BasePill from "../components/BasePill.vue";
import { useSavedData } from "../store/savedDataStore";

guardNoCookie();
useProgress().set(14);

function isSelected(value) {
  const values = useSavedData().data.value.your_focus;
  if (!values) return false;
  const valuesArray = values.split(',');
  return valuesArray.includes(value);
}

const ANSWERS = reactive([
  { text: "Weight loss", value: "Weight", selected: false },
  { text: "Body sculpting", value: "Body", selected: false },
  { text: "Fat Burn", value: "Fat", selected: false },
  { text: "Libido Boost", value: "Libido", selected: false },
  { text: "Face Lifting", value: "Face", selected: false },
  { text: "Stress Reduction", value: "Stress", selected: false },
  { text: "Flexibility", value: "Flexibility", selected: false },
  { text: "Strength", value: "Strength", selected: false },
  { text: "Energy Boost", value: "Energy", selected: false },
]);

const isAnyAnswerSelected = computed(() => {
  return ANSWERS.some((answer) => answer.selected);
});

const dataToSend = computed(() => {
  return ANSWERS.filter((answer) => answer.selected).map((answer) => answer.value).join(",");
});

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
    ANSWERS.forEach((answer) => {
    answer.selected = isSelected(answer.value);
  });
});
</script>
<style scoped>
.options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
</style>