<script setup>
  import { useProgress } from '../logic/useProgress';
  import { ref } from "vue";

  //const appDomain = ref(process.env.VUE_APP_DOMAIN_CAP);
  //const appURL = ref(process.env.VUE_APP_URL);
  const appName = ref(process.env.VUE_APP_NAME);

  useProgress().set(0);

</script>


<template>
  <img src="../../public/logo.png" alt="logo" width="120px" class="logo" />
  <h1>Terms of Service</h1>

  <h2>1. Introduction</h2>
  <p>Greetings from {{appName}}, your essential partner in achieving wellness objectives. By utilizing our app, you consent to adhere to the terms and conditions specified in this agreement.</p>
  <h2>2. Acceptance of Terms</h2>
  <p>When you download, install, or access {{appName}} in any manner, you confirm that you have reviewed, understood, and consent to comply with these Terms of Use. Should you disagree with any section of these terms, you are prohibited from using the app.</p>
  <h2>3. User Eligibility</h2>
  <p>To use {{appName}}, you must be at least 18 years old or possess parental or guardian consent. By engaging with the app, you assert that you satisfy these criteria.</p>
  <h2>4. User Accounts</h2>
  <p>Creating a {{appName}} account is necessary to use certain Services, and maintaining an accurate and current account (especially your email address for password recovery purposes) is crucial.</p>
  <p>You must register an account to access specific services, which may automatically grant you access to new services.</p>
  <p>Account creation requires you to provide complete and accurate information during the registration process and keep this information current. Failure to do so may affect the functionality of our Services and our ability to provide you with important notifications.</p>
  <p>You are responsible for all activities under your account and must immediately report any loss, theft, or unauthorized use of your account or password to our Support Team. We are not liable for losses incurred from unauthorized account use.</p>
  <h2>5. Usage Restrictions</h2>
  <p>As a user of {{appName}}, you are prohibited from using the product to:</p>
  <p>5.1. engage in commercial or revenue-generating activities;</p>
  <p>5.2. create competing products, services, or software;</p>
  <p>5.3. partake in fraudulent, criminal, or illegal activities.</p>
  <p>Additionally, you must not:</p>
  <p>5.4. alter, adapt, improve, or translate the Product, or create derivative works from it;</p>
  <p>5.5. decode, decompile, dismantle, or reverse engineer any part of the software included in the Product;</p>
  <p>5.6. disable or interfere with the security features of the Product;</p>
  <p>5.7. bypass any restrictions or measures intended to prevent unauthorized access to the Product;</p>
  <p>5.8. deploy automated systems like bots, or unauthorized scripts and software that interact with the Product;</p>
  <p>5.9. transmit files that may damage another's computer, such as viruses or corrupted data;</p>
  <p>5.10. allow access to the Product on networks or systems that enable simultaneous use by multiple users;</p>
  <p>5.11. link to the Product without our express permission, unless done so legally and without damaging our reputation;</p>
  <p>5.12. undertake actions that might disrupt or overload the Product;</p>
  <p>5.13. engage in behavior that could negatively affect our reputation or the Product.</p>
  <h2>6. Intellectual Property</h2>
  <p>The intellectual property, including texts, graphics, logos, and software, found on {{appName}}, belongs to MINDSPARKLE LLC or its licensors and is protected by copyright and intellectual property laws.</p>
  <h2>7. Privacy Policy</h2>
  <p>We value your privacy highly. To understand how we handle your personal information during your use of {{appName}}, please review our Privacy Policy.</p>
  <h2>8. Payment Terms</h2>
  <h3>8.1 Payment Terms</h3>
  <p>Subscriptions are available for the Product Content and can be acquired either directly through the Website (termed as "Web Purchase") or through the App Store (referred to as "In-App Purchase"). All relevant charges will be clearly displayed during the payment process and at checkout before you confirm any payment for the Product. Additionally, certain Product Content might be accessible to you at no cost.</p>
  <h3>8.2 Web Purchase</h3>
  <p>a. Procedure for Web Purchase: Upon completing a questionnaire on our Website, you will have the option to subscribe to the Product Content, with clear details on subscription fees, terms, and duration options (such as 1 week, 1 month, 3 months, 1 year) alongside available payment methods (like Mastercard, Visa, Apple Pay, Google Pay). To proceed with a Web Purchase, select your desired option and authorize payment on the payment screen. Note that we utilize third-party payment processors to facilitate transactions, which you authorize to charge the fee through your selected payment method. These processors are responsible for securing your transaction and notifying us upon its successful completion. Your credit or debit card details will be securely encrypted. Following successful payment validation by our servers, we will directly send you instructions to access the purchased Product Content.</p>
  <p>b. Auto-Renewal: Engaging in a Web Purchase means you agree that your subscription will renew automatically, unless you cancel according to the instructions provided. Charges for renewal will occur periodically based on the subscription duration initially chosen, and the subscription will renew at the end of each term unless canceled at least 24 hours before it ends. You are reminded that deleting the app does not cancel your subscription. The auto-renewal will maintain the original subscription duration unless specified otherwise, and the renewal charge will not exceed the rate of the prior period, barring any rate changes communicated before your renewal.</p>
  <p>c. Refunds: Web Purchase refunds adhere to our Refund Policy. Generally, these purchases are considered final and are not eligible for cancellation or refund. However, refunds may be issued in circumstances where mandatory legal provisions necessitate it.</p>
  <h3>8.3 In-App Purchase</h3>
  <p>a. Procedure for In-App Purchase: After completing the questionnaire within the App, you will be presented with the option to subscribe to the Product Content, with details on fees, terms, and the period of subscription clearly outlined. To make an In-App Purchase, select an option and authorize the payment, which will be processed by the App Store. You authorize the App Store to handle the payment using the method you have provided. Access to the Product Content will be granted promptly after the App Store confirms the transaction's success.</p>
  <p>b. Auto-Renewal: By initiating an In-App Purchase, you acknowledge and consent to the automatic renewal of your subscription, which you can cancel as described in the provided guidelines. Like Web Purchases, the subscription automatically renews unless it is canceled at least 24 hours before the end of the current period. Again, deleting the app does not affect subscription status. Renewal terms will mirror the initial period unless otherwise specified, and the renewal price will be capped at the price of the previous period unless you are notified of a price increase before renewal.</p>
  <p>c. Refunds: In-App Purchase refunds are not handled by us but can be processed through the respective App Store's refund policies. You should direct refund requests to Apple App Store or Google Play Market as applicable.</p>
  <h3>8.4 Free Trials</h3>
  <p>We occasionally offer free trial access to our Product Content, but this is not guaranteed. Free trials are provided for a specified duration and under terms set out at the commencement of the trial. If not canceled at least 24 hours before the trial ends, your access will automatically convert to a paid subscription, and charges will apply based on the terms agreed upon at the start of the trial. Detailed cancellation instructions are provided, and it is important to note that uninstalling the app does not cancel the trial. We reserve the right to alter or discontinue any free trial offer at any time without notice and without liability, and to limit eligibility for free trials.</p>
  <h3>8.5 Fee Changes</h3>
  <p>Subject to applicable laws, we reserve the right to modify subscription fees at any time, with such changes effective upon the next renewal cycle. We will provide reasonable notice of any fee adjustments via the App or through an email notification. Should you disagree with the changes, you have the option to cancel the subscription before the new fees take effect.</p>
  <h3>8.6 Non-payment and Collection</h3>
  <p>Should there be any non-payment of dues, we will attempt to contact you to resolve the issue. However, if unresolved, we retain the right to terminate your access to the Product and these Terms, potentially without prior notice.</p>
  <h2>9. Disclaimer of Warranties</h2>
  <h3>9.1 Agreement Basis</h3>
  <p>You acknowledge and agree that our pricing and agreement to these terms are based on your acceptance of the disclaimers, limitations of liability, and indemnity clauses outlined here, which help distribute risks between us and underpin our contractual agreement. Without these clauses, we might not have been able to provide access to the product or would have had to approach the terms differently.</p>
  <h3>9.2 Disclaimer on Medical Advice</h3>
  <p>{{appName}} is intended solely for informational and entertainment purposes and does not provide medical advice. You agree that the content from {{appName}} should not replace professional medical advice, diagnoses from your doctor, or any information found on medical product labels. Always seek the advice of your healthcare provider with any questions you may have regarding a medical condition, and dial emergency services immediately in case of a critical condition. Relying on any information provided by {{appName}} is strictly at your own risk.</p>
  <h3>9.3 Health and Wellness Responsibility</h3>
  <p>You agree to use {{appName}} only if you are in good health and have confirmed with a healthcare provider your ability to engage in active or passive health and fitness activities. Any concerns about your health should prompt a consultation with a healthcare professional before using the product. It is your responsibility to assess whether using {{appName}} is appropriate for your physical condition and health status.</p>
  <h3>9.4 Nutritional Disclaimer</h3>
  <p>{{appName}} does not accept liability for any inaccuracies or incorrect information about nutritional data or dietary suggestions presented in the product. It is your responsibility to scrutinize all product labels, nutritional specifics, and manufacturer details before consuming or using food products. Always confirm that the dietary content suits your personal health requirements and allergies.</p>
  <h3>9.5 Individual Results Disclaimer</h3>
  <p>{{appName}} makes no promises regarding the specific outcomes you may achieve using the product as individual results vary greatly. These variations result from each person's unique genetic makeup, health status, background, dedication, and motivation. Thus, results seen in testimonials or other examples in the product do not guarantee similar outcomes for everyone.</p>
  <h3>9.6 Accuracy of Information</h3>
  <p>You are responsible for providing accurate and complete information during the completing the questionnaire, which we use to tailor your experience with the product. If you provide incorrect or incomplete information, {{appName}} cannot be held responsible for the inaccuracy of the fitness plans or advice provided.</p>
  <h3>9.7 Use at Own Risk</h3>
  <p>Your use of {{appName}} is solely at your own risk. The product is provided on an "as is" and "as available" basis. The Released Parties explicitly disclaim all warranties, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that the product will meet your requirements, that it will be uninterrupted or error-free, or that defects in the product will be corrected.</p>
  <h3>9.8 Third-Party Services</h3>
  <p>{{appName}} contains links to third-party services or websites, but we are not responsible for the content or services provided by these third parties. It is your responsibility to evaluate the safety, reliability, and trustworthiness of any third-party service or content. We do not endorse, nor are we responsible for, the accuracy or reliability of any information, data, opinions, or statements made on these third-party sites.</p>
  <h3>9.9 Non-Endorsement of Third-Party References</h3>
  <p>References to third-party products or experts within {{appName}} do not constitute an endorsement of such products or expertise. It is your responsibility to evaluate the quality and dependability of any third-party products or advice referenced by {{appName}}.</p>
  <h3>9.10 Precedence of English Terms</h3>
  <p>The original English version of these terms takes precedence over any other translations. If there is any conflict between the English version and a translation, the English version prevails.</p>
  <h3>9.11 User Content Responsibility</h3>
  <p>You own all content you generate through {{appName}}, but you are solely responsible for its legality and appropriateness. {{appName}} does not pre-screen user-generated content and, as such, does not guarantee its accuracy or quality. You agree that {{appName}} is not responsible for any user content and that such content is not confidential except as specified in our privacy policy. {{appName}} will not be liable for any use or disclosure of any user-generated content you provide.</p>
  <h2>10. Limitation of Liability</h2>
  <h3>10.1 General Limitation:</h3>
  <p>While we strive to deliver the best possible product, we acknowledge that it is not infallible.</p>
  <p>We disclaim liability for any issues that may arise from your use of the product or factors beyond our control. Under no circumstances will we, including our subsidiaries, affiliates, related entities, successors, officers, agents, employees, contractors, partners, or licensors (collectively referred to as the "Released Parties"), be liable for any direct or indirect losses or damages you or any third party may suffer. This includes, but is not limited to, loss of profit, goodwill, opportunity, data, indirect, consequential, exemplary, incidental, special, or punitive damages, personal injury, or death related to your use or misuse of the product, or from your engagement with any third-party service providers. This limitation applies even if the Released Parties have been advised of the possibility of such damages.</p>
  <p>The maximum liability of the company, regardless of the circumstances, shall not exceed the greater of the total fees you have paid to the company in the last 12 months or $50. You also waive any rights afforded by section 1542 of the California Civil Code (and any similar law of any state, country, or territory), or any similar provision in other jurisdictions, which states that a general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release.</p>
  <h3>10.2 No Exclusion or Limitation for Unlawful Acts:</h3>
  <p>Our liability to you for acts that cannot legally be excluded or limited will not be restricted. This includes liability for death or personal injury resulting from our negligence or the negligence of our employees, agents, or subcontractors, as well as any other liabilities that the law does not allow us to exclude or limit.</p>
  <h3>10.3 Time Limit for Claims:</h3>
  <p>You must bring any claims arising under these terms within one year after the cause of action occurs, otherwise, such claims or causes of action will be permanently barred.</p>
  <h2>11. Indemnification</h2>
  <p>You agree to defend, indemnify, and hold harmless the Indemnified Parties from any claims, liabilities, costs, or expenses, including reasonable attorneys' fees, arising out of or related to your:</p>
  <p>&nbsp;&nbsp;&nbsp;Use or misuse of {{appName}};</p>
  <p>&nbsp;&nbsp;&nbsp;Breach of these Terms or any applicable policies;</p>
  <p>&nbsp;&nbsp;&nbsp;Violation of any laws;</p>
  <p>&nbsp;&nbsp;&nbsp;Provision of inaccurate or unlawful information to any of the Indemnified Parties;</p>
  <p>&nbsp;&nbsp;&nbsp;Misrepresentation made by you to any of the Indemnified Parties;</p>
  <p>&nbsp;&nbsp;&nbsp;Interaction with any third-party websites, products, or services;</p>
  <p>&nbsp;&nbsp;&nbsp;Violation of any third party's rights;</p>
  <p>&nbsp;&nbsp;&nbsp;Disputes or issues between you and any third party.</p>
  <p>We reserve the right, at your cost, to assume the exclusive defense and control of any matter subject to indemnification by you, and you agree to cooperate with our defense of these claims. You must not settle any claim without our prior written consent. We will endeavor to notify you of any such claim, action, or proceeding upon becoming aware of it.</p>
  <h2>12. Modification of Terms</h2>
  <p>We may revise these Terms of Use at any time without prior notice. Changes will take effect immediately upon their posting on the app. By continuing to use {{appName}} after these changes are posted, you agree to the revised terms.</p>
  <h2>13. Termination</h2>
  <p>We reserve the right to terminate your access to the Service if you breach any terms. We may also, at our discretion, terminate your account or suspend your access to the Service without prior notice. We may alter or discontinue the Service at any time without notice and will not be liable for any modification, suspension, or discontinuation. You can terminate your account at any time by unsubscribing or contacting customer support. Upon termination, you remain liable for any outstanding charges incurred before termination.</p>
  <h2>14. Governing Law and Dispute Resolution</h2>
  <h3>14.1 Informal Resolution</h3>
  <p>We prioritize resolving disputes in a non-confrontational, efficient manner. Should you encounter any disagreements with the Company, it is agreed that before taking formal legal action, you will contact us to provide a concise, written description of the issue at hand. Both parties will engage in good faith negotiations to attempt to resolve the dispute amicably.</p>
  <h3>14.2 Arbitration Agreement</h3>
  <p>Please carefully review this Arbitration Agreement, as it significantly alters your legal rights, including the right to take disputes to court.</p>
  <p>a. Scope of the Arbitration Agreement: Both you and {{appName}} agree that any disputes, whether based on contract, tort, statute, fraud, or any other legal theory, and whether arising before, during, or after the termination of these Terms, will be resolved through binding individual arbitration, not in court. This includes disputes that might otherwise be taken to court, except for matters permissible in small claims court and issues concerning the protection of {{appName}}'s intellectual property or unless you opt out within 30 days of these terms taking effect. This Arbitration Agreement persists after the termination of these Terms.</p>
  <p>b. Small Claims Court Option: Either party may bring an individual action in small claims court if the dispute falls within that court's jurisdiction.</p>
  <p>c. Intellectual Property Protection: {{appName}} may seek to enforce or protect its intellectual property rights through injunctive or equitable relief from a court.</p>
  <p>d. Opt-Out Provision: You have 30 days from accepting these Terms to opt-out of the Arbitration Agreement by notifying us in writing. If not opted out, you are bound to resolve disputes in accordance with the terms of this Arbitration Agreement.</p>
  <p>e. Arbitration Procedures: Arbitration replaces the right to go to court and is generally a more expedited process. Arbitration will be conducted under the rules of the American Arbitration Association ("AAA"), which are available online or by phone. You or {{appName}} may initiate arbitration, with procedures involving a written notice that outlines the nature and basis of the claim. If unresolved within 30 days of notice, formal proceedings may commence. Arbitration may occur via written submissions only, unless an in-person hearing is required.</p>
  <p>f. No Class or Representative Proceedings: Claims must be brought individually, and not as part of any class or representative action. The arbitrator may not consolidate more than one person's claims and may not oversee any form of class or collective proceeding.</p>
  <p>g. Confidentiality: All aspects of the arbitration process are strictly confidential, and the information disclosed during the process may not be used for any other purpose than the arbitration itself or enforcing the arbitrator's decision.</p>
  <p>h. Legal Framework: This Arbitration Agreement is governed by the Federal Arbitration Act. In the event that any part of this Agreement is deemed invalid or unenforceable, the remainder of the arbitration terms will remain enforceable. If invalidation leads to litigation, such disputes will be resolved in court under applicable law.</p>
  
  <h2>15. Contact Information</h2>
  <p>For any inquiries or concerns regarding these Terms of Use, please reach out to us at <img src="../assets/images/ei.png" alt="support" class="image" /></p>
  <h2>16. Effective Date</h2>
  <p>These Terms of Use are effective as of 5/15/2024  and were last updated on 5/15/2024. They govern the use of {{appName}} from this date onwards until further updates are made.</p>
</template>
<style scoped>
  p {
    font-size: 16px;
    text-align:justify;
  }

  h2 {
    text-align: left;
  }

  h3 {
    text-align: left;
  }
  .linkText {
    text-transform: capitalize;
    text-decoration: none;
    color: #4263eb;
  }
  .image {
    vertical-align: top; /* Zarovnání obrázku nahoře */
    margin-top: 1px;
  }

</style>
