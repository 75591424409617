<script setup>
import { ref } from "vue";
import BaseButton from "./BaseButton.vue";
defineEmits(["ok"]);
const chartOptions = ref({
  chart: {
    type: "line",
    dropShadow: {
      enabled: true,
      color: "#000",
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2,
    },
    toolbar: {
      show: false,
    },
  },

  annotations: {
    points: [
      {
        x: "Week 1",
        y: 90,
        marker: {
          size: 0,
          fillColor: "#fff",
          strokeColor: "red",
          radius: 2,
          cssClass: "apexcharts-custom-class",
        },
      },
      {
        x: "Week 3",
        y: 76,
        marker: {
          size: 0,
          fillColor: "#fff",
          strokeColor: "red",
          radius: 2,
          cssClass: "apexcharts-custom-class",
        },
        label: {
          borderColor: "#4263eb",
          offsetY: 0,
          offsetX: 0,
          style: {
            color: "#fff",
            background: "#4263eb",
          },

          text: "FitSpire Users",
        },
      },
      {
        x: "Week 3",
        y: 90,
        marker: {
          size: 0,
          fillColor: "#fff",
          strokeColor: "red",
          radius: 2,
          cssClass: "apexcharts-custom-class",
        },
        label: {
          borderColor: "#545454",
          offsetY: 0,
          offsetX: 0,
          style: {
            color: "#fff",
            background: "#545454",
          },

          text: "Average User",
        },
      },
      {
        x: "Week 4",
        y: 81,
        marker: {
          size: 8,
          fillColor: "#fff",
          strokeColor: "#ccc",
          strokeOpacity: 0.1,
          strokeWidth: 3,
          radius: 4,
          cssClass: "apexcharts-custom-class",
        },
      },
      {
        x: "Week 4",
        y: 89,
        marker: {
          size: 8,
          fillColor: "#fff",
          strokeColor: "#ccc",
          strokeOpacity: 0.1,
          strokeWidth: 3,
          radius: 4,
          cssClass: "apexcharts-custom-class",
        },
      },
    ],
  },

  colors: ["#60e05c", "#545454"],
  dataLabels: {
    enabled: false,
  },
  stroke: { width: 9, curve: "smooth" },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      gradientToColors: ["#4263eb", "#545454", "#00FF00"],
      shadeIntensity: 0.5,
      type: "horizontal",
      opacityFrom: 0.9,
      opacityTo: 0.9,
      stops: [50, 60, 100],
    },
  },
  grid: {
    borderColor: "#e7e7e7",
    row: {
      opacity: 0.5,
    },
  },
  markers: {
    size: 0,
  },
  xaxis: {
    categories: ["Week 1", "Week 2", "Week 3", "Week 4"],
    title: {
      text: "Weeks",
    },
  },
  yaxis: {
    title: {
      text: "Weight",
    },
    min: 70,
    max: 100,
  },
});

const chartSeries = ref([
  {
    name: "FitSpire Users",
    data: [90, 87, 84, 81],
  },
  {
    name: "Average User",
    data: [90, 89, 88, 89],
  },
]);
</script>
<template>
  <div style="width: 100%; height: 100%">
    <h2 style="margin: 32px 0">Did you know?</h2>
    <p style="margin: 0 16px">
      Up to <b>70%</b> of users have successfully achieved their fitness goals
      <b>within the first month</b> with workout plans that include goal-setting
      strategies.
    </p>
    <div class="chart">
      <apexchart
        type="line"
        :options="chartOptions"
        :series="chartSeries"
        width="400px"
        height="280px"
      />
    </div>
    <div class="infoBox">
      <p>
        We're here to support your success, so we're giving you an
        <b>extra discount</b> on your <b>FitSpire Plan</b>.
      </p>
    </div>
    <BaseButton :width="300" @click="$emit('ok')">Got It</BaseButton>
  </div>
</template>

<style scoped>
.chart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.infoBox {
  margin: 0 24px 32px 24px;
  border-radius: 12px;
  background-color: #ececec;
  padding: 8px;
}
</style>
