<script setup>
const props = defineProps({
  disabled: Boolean,
  color: {
    type: String,
    default: "#4263eb",
  },
  width: {
    type: Number,
    default: 600,
  },
  secondary: Boolean,
  pulsating: Boolean,
});
</script>

<template>
  <button
    type="button"
    :class="[
      'el',
      { disabled: props.disabled },
      { clickable: !props.disabled },
      { secondary: props.secondary },
      { pulsating: props.pulsating },
    ]"
    :disabled="disabled"
    :style="{ maxWidth: `${props.width}px` }"
  >
    <slot />
  </button>
</template>

<style scoped>
.el {
  width: 100%;
  align-items: center;
  border-radius: 9px;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.9375rem;
  font-weight: normal;
  height: 64px;
  justify-content: center;
  padding: 24px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  z-index: 0;

  background-color: v-bind(color);
  border: none;
  color: white;
  &.clickable {
    &:hover {
      opacity: 0.9;
    }
  }
}
.secondary {
  background-color: white;
  color: #4263eb;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.clickable {
  transition-duration: 200ms;
  transition-property: background-color, box-shadow, transform;
  &:active {
    transform: scale(0.85);
  }
}
.pulsating::before {
  content: "";
  position: absolute;
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
  border-radius: inherit;
  background-color: inherit;
  opacity: 0.2;
  z-index: -1;
  animation: pulsate 1s infinite;
}

@keyframes pulsate {
  0% {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  50% {
    width: 105%;
    height: 130%;
    top: -15%;
    left: -2.5%;
  }
  100% {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
</style>
