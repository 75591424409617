<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>Thank You for Choosing {{appName}}!</h2>

    <p>
      We're thrilled to welcome you to our community. Your training plan has
      been meticulously crafted, and your account is all set up. We're delighted
      to confirm that your payment was successful {{ userData.stripe_paystat }}.
    </p>
    <img src="../assets/images/thanks.png" alt="Our Users" class="image" />
    <p style="margin-top: 0">
      Warmest regards,<br />
      <strong>The {{appName}} Team</strong>
    </p>
    <BaseButton
      :width="280"
      @click="saveAndNavigate('continue', '', '/Registration')"
    >
      CREATE MY ACCOUNT</BaseButton
    >
    <div style="display: flex; justify-content: center; margin-top: 24px">
      <BaseInfo
        text="If you have any questions or require assistance, please don't hesitate to "
        text-after="We wish you a fulfilling journey toward achieving a more mindful and balanced self!"
        ><a class="linkText" href="/Support-Ticket" target="_blank"
          >contact us.</a
        ></BaseInfo
      >
    </div>
  </div>
</template>

<script setup>
import { gtagEvent } from "@/logic/gtagEvent";
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import axios from "axios";
import { onMounted, ref } from "vue";
import BaseButton from "../components/BaseButton.vue";
import BaseInfo from "../components/BaseInfo.vue";
const appName = ref(process.env.VUE_APP_NAME);

guardNoCookie();
useProgress().set(34);

const loading = ref(true);
const userData = ref({});

(() => {
  loading.value = true;
  axios
    .get(`/api/Stripe/query-payment-status?sid=${store.state.uid}`)
    .then((response) => {
      userData.value = response.data;
      loading.value = false;
    })
    .catch((error) => {
      console.error("Došlo k chybě při načítání dat:", error);
      loading.value = false;
    });
})();

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
  gtagEvent("purchase", "");
});
</script>
<style scoped>
.image {
  width: 100%;
  max-width: 400px;
}
.linkText {
  text-decoration: none;
  color: #4263eb;
}
</style>
