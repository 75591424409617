<script setup>
import { ref } from 'vue';
import BaseButton from '../components/BaseButton.vue';
import BaseEmailBox from '../components/BaseEmailBox.vue';
import { useProgress } from '../logic/useProgress';
const emailInput = ref();
const isValidEmail = ref(false);
useProgress().set(0);

async function resetPassword(){
    try {
    await axios.post('/api/VarStore/ForgetPassword', {
      email: emailInput.value,
    });

  } catch (error) {
    console.error('Reset Password error:', error);
  }
}
</script>

<template>

    <div class="wrap">

         <div class="left">
        <div class="background"/>
         <img src="../assets/images/users.png" alt="Our Users" class="image" />
         </div>
         <div class="content">
        <img src="../../public/logo.png" alt="logo" width="80px" class="logo"/>
    <h1>Restore Password</h1>
    <p style="font-size: 16px;">We will send you an email with instructions on how to reset your password</p>
        <BaseEmailBox
    v-model="emailInput"
    :width="600"
      placeholder="Enter your email address."
      error-message="Kindly provide a valid email address."
      @update:is-valid="isValidEmail = $event"
    />
    <BaseButton :disabled="!isValidEmai" @click="resetPassword"
    >SEND RESET LINK</BaseButton>
    <p class="subText">Return to <RouterLink to="/Login" class="linkText">Log In</RouterLink></p>
    </div>
    </div>
</template>

<style scoped>
.wrap{
  display: flex;
  gap: 64px;
  margin-top: 64px;
}
.image {
  width: 100%;
  max-width: 700px;
  z-index: 2; 
}

.background{
display: flex;
position: absolute;
top: 0;
left: 0;
width: 50%;
height: 100%;
  background-color: rgba(66, 99, 235, 0.15);
  z-index: 0;
}
.content {
    width: 100%;
    flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
    @media screen and (max-width: 768px){
        width: 100%;
    }
}

.left{
    width: 100%;
    flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;

  @media screen and (max-width: 768px){
    display: none;
    
  }
}
.logo {
  position: absolute;
  top: 0;
  left: 0;
  margin: 16px;
  z-index: 3;
  @media screen and (max-width: 768px){
    position: relative;
    margin: 0;
    
    
  }
}
.linkText {
    text-transform: capitalize;
    text-decoration: none;
    color: #4263eb;
    font-size: 16px;
}
.subText {
    margin-top: 20px;
    font-size: 16px;
}
</style>