<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>How much sleep do you get?</h2>

    <div class="options">
      <BaseOption
        text="Fewer than 5 hours"
        :img-src="require('../assets/images/flushed.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('sleep_have','5-')"
        @click="saveAndNavigate('sleep_have', '5-', '/Water-Every-Day')"
      />
      <BaseOption
        text="Between 5 and 6 hours"
        :img-src="require('../assets/images/thinking.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('sleep_have','5-6')"
        @click="saveAndNavigate('sleep_have', '5-6', '/Water-Every-Day')"
      />
      <BaseOption
        text="Between 7 and 8 hours"
        :img-src="require('../assets/images/smiling.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('sleep_have','7-8')"
        @click="saveAndNavigate('sleep_have', '7-8', '/Water-Every-Day')"
      />
      <BaseOption
        text="Over 8 hours"
        :img-src="require('../assets/images/glasses.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('sleep_have','8+')"
        @click="saveAndNavigate('sleep_have', '8+', '/Water-Every-Day')"
      />
      </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { onMounted } from "vue";
import BaseOption from "../components/BaseOption.vue";
import { useSavedData } from "../store/savedDataStore";
guardNoCookie();
useProgress().set(16);

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
const { isSelected } = useSavedData();

</script>
<style scoped>
.options {
  margin: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>