<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <div style="display: flex; justify-content: center; width: 100%">
      <div>
        <h2>When's your event?</h2>
        <BaseDateBox
          v-model="dateInput"
          error-message="Please input a future date for your event."
          @update:is-valid="isValidDate = $event"
        />
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
          "
        >
          <BaseButton
            :disabled="!isValidDate"
            @click="saveAndNavigate('your_event', dataToSend, '/One-Only-Plan')"
          >
            CONTINUE
          </BaseButton>
          <BaseButton
            secondary
            @click="saveAndNavigate('your_event', '', '/One-Only-Plan')"
          >
            SKIP THIS QUESTION
          </BaseButton>
        </div>
      </div>
      <img
        :src="
          isFemale
            ? require('../assets/images/sideTimeW.png')
            : require('../assets/images/sideTime.png')
        "
        alt="Activity"
        class="side-image"
      />
    </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { computed, onMounted, ref } from "vue";
import BaseButton from "../components/BaseButton.vue";
import BaseDateBox from "../components/BaseDateBox.vue";
import { useSavedData } from "../store/savedDataStore";
guardNoCookie();
useProgress().set(26);

const dateInput = ref();
const isValidDate = ref(false);
const isFemale = computed(() => store.state.gender === "female");
const dataToSend = computed(() => {
  return `${dateInput.value}`;
});

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
  if (useSavedData().data.value.your_event) {
    dateInput.value = useSavedData().data.value.your_event;
  }
});
</script>

<style scoped>
.side-image {
  width: 200px;
  position: absolute;
  right: 210px;
  top: 460px;
  @media (max-width: 1200px) {
    display: none;
  }
}
</style>
