<script setup>
import { useProgress } from "../logic/useProgress";
useProgress().set(0);
</script>
<template>
  <div class="loader-container">
    <div class="loader" />
  </div>
</template>

<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  margin-top: 32px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #4263eb;
  width: 50px;
  height: 50px;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
