import { createStore } from "vuex";

export default createStore({
  state: {
    totalSteps: 34,
    step: 1,
    uid: "",
    lpv: "",
    cr: "",
    hr: "",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_content: "",
    baseURL: "",
    country: "",
    height: "",
    height_unit: "",
    weight: "",
    weight_unit: "",
    goal_weight: "",
    goal_weight_unit: "",
    age: "",
    spk: "",
    gender: "",
    auth: "",
  },
  mutations: {
    setStep(state, value) {
      state.step = value;
    },
    setUID(state, value) {
      state.uid = value;
      state.baseURL = `${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ""
      }`;
    },
    setGender(state, value) {
      state.gender = value;
    },
    setAuth(state, value) {
      state.auth = value;
    },
    setLpv(state, value) {
      state.lpv = value;
    },
    setCR(state, value) {
      state.cr = value;
    },
    setHR(state, value) {
      state.hr = value;
    },
    setSource(state, value) {
      state.utm_source = value;
    },
    setMedium(state, value) {
      state.utm_medium = value;
    },
    setCampaign(state, value) {
      state.utm_campaign = value;
    },
    setContent(state, value) {
      state.utm_content = value;
    },
  },
  actions: {},
  getters: {},
});
