<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>Summary of your fitness level</h2>
    <div
      style="
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 16px 0;
      "
    >
      <div class="chartBox">
        <div class="head">
          <p style="font-size: 16px">Body Mass Index (BMI)</p>
        </div>
        <div id="bmiSliderContainer">
          <div id="customSlider" class="custom-slider">
            <div class="custom-slider-track" />
            <div id="customSliderThumb" class="custom-slider-thumb" />
          </div>
          <div
            id="bmiValue"
            style="font-size: 14px; color: #495057; min-width: 56px"
          >
            You - 0
          </div>
          <div class="bmi-labels-container">
            <div class="bmi-label">Normal</div>
            <div class="bmi-label" style="">Overweight</div>
            <div class="bmi-label" style="">Obese</div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 16px;
      "
    >
      <div style="margin-right: 20px">
        <p>
          {{appSport}} level - <b>{{ yoga_level }}</b>
        </p>
        <p>
          Body type - <b>{{ userData.body_type_have }}</b>
        </p>
        <p>
          Activity level - <b>{{ act_level_desc }}</b>
        </p>
      </div>
      <img
        v-if="bodyImage"
        :src="bodyImage"
        alt="body type"
        style="width: 180px; height: 180px"
      />
    </div>
    <div style="display: flex; justify-content: center" class="sticky-content">
      <BaseInfo
        style="margin-bottom: 32px"
        title="Risk of unhealthy BMI"
        text="The risks of an unhealthy BMI include high blood pressure, heart disease, stroke, type 2 diabetes, some types of cancer, osteoarthritis, back pain, and all case mortality."
      />
    </div>
    <div class="sticky-button">
      <BaseButton @click="saveAndNavigate('continue', '', '/Special-Occasion')">
        CONTINUE
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import BaseButton from "../components/BaseButton.vue";
import BaseInfo from "../components/BaseInfo.vue";

const appSport = ref(process.env.VUE_APP_SPORT);

guardNoCookie();
useProgress().set(24);

const act_level_desc = ref("");

const loading = ref(true);
const userData = ref({});
const yoga_level = ref("");

const bodyImage = computed(() => {
  if (!userData.value.body_type_have) return;
  const bodyType = userData.value.body_type_have;
  const gender = userData.value.gender;
  if (gender === "female") {
    if (bodyType === "Regular") return require("../assets/images/regularW.png");
    if (bodyType === "Flabby") return require("../assets/images/flabbyW.png");
    if (bodyType === "Extra") return require("../assets/images/fatW.png");
  } else {
    if (bodyType === "Regular") return require("../assets/images/regular.png");
    if (bodyType === "Extra") return require("../assets/images/extra.png");
  }
  return require("../assets/images/flabby.png");
});

(() => {
  loading.value = true;
  axios
    .get(`/api/VarStore/Session?sid=${store.state.uid}`)
    .then((response) => {
      userData.value = response.data;
      //this.unit = response.data.weight_unit;
      loading.value = false;
      if (userData.value?.activity_level === "1")
        act_level_desc.value = "Sedentary";
      else if (userData.value?.activity_level === "2")
        act_level_desc.value = "Somewhat active";
      else if (userData.value?.activity_level === "3")
        act_level_desc.value = "Moderately active";
      else if (userData.value?.activity_level === "4")
        act_level_desc.value = "Very active";
      else if (userData.value?.activity_level === "5")
        act_level_desc.value = "Highly active";

      if (userData.value?.yoga_level === "Beginner")
        yoga_level.value = "Beginner";
      else if (userData.value?.yoga_level === "ExpBeg")
        yoga_level.value = "Experienced Beginner";
      else if (userData.value?.yoga_level === "Intermediate")
        yoga_level.value = "Intermediate";
      else if (userData.value?.yoga_level === "Advanced")
        yoga_level.value = "Advanced";
    })
    .catch((error) => {
      console.error("Došlo k chybě při načítání dat:", error);
      loading.value = false;
    });
})();

axios
  .get(`/api/VarStore/GetBMIData?sid=${store.state.uid}`)
  .then((response) => {
    const data = response.data;
    animateSliderToValue(data.bmi, 65);
    updateSliderThumbPosition(data.bmi, 65);
  })
  .catch((error) => {
    console.error("Došlo k chybě při načítání dat:", error);
    loading.value = false;
    animateSliderToValue(80, 65);
    updateSliderThumbPosition(80, 65);
  });

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});

function animateSliderToValue(apiValue, maxValue) {
  const thumb = document.getElementById("customSliderThumb");
  const slider = document.getElementById("customSlider");
  let sliderRect = slider.getBoundingClientRect();
  const fixedValue = apiValue > 60 ? 60 : apiValue;
  const targetPosition = (fixedValue / maxValue) * sliderRect.width;
  thumb.style.left = `${targetPosition}px`;
}

function updateSliderThumbPosition(apiValue, maxValue) {
  const display = document.getElementById("bmiValue");
  display.textContent = `You - ${apiValue}`;
  const fixedValue = apiValue > 60 ? 60 : apiValue;
  const percentage = (fixedValue / maxValue) * 100;
  display.style.left = `calc(${percentage}% + (${8 - percentage * 0.15}px))`;
}
</script>

<style scoped>
.custom-slider {
  position: relative;
  width: 320px;
  height: 10px;
  background-color: #ddd;
}

.custom-slider-track {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    #78c850 0%,
    #f7d038 33%,
    #ff7f0f 66%,
    #e51c23 100%
  );
}

.custom-slider-thumb {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #6c757d;
  left: 0;
  top: 50%;
  transition: left 1.5s ease-out;
  transform: translateY(-50%);
}

#bmiSliderContainer {
  position: relative;
  width: 320px;
  margin: 20px auto;
}

#bmiValue {
  position: absolute;
  bottom: 55px;
  left: 0;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: left 1.5s ease-out;
}

.bmi-labels-container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.bmi-label {
  padding: 6px;
  font-size: 12px;
  text-transform: uppercase;
  color: #6c757d;
}

.head {
  display: flex;
  text-align: left;
  width: 320px;
  margin-bottom: 24px;
}

.chartBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  width: 400px;
  border-radius: 12px;
}
</style>
