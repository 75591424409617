<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>Are you familiar with calisthenics?</h2>
    <div class="options">
      <BaseOption text="Yes, I practice regularly"
                  :img-src="
          isFemale
            ? require('../assets/images/profiYogaW.png')
            : require('../assets/images/chair.png')
        "
                  img-alt="Man"
                  is-single
                  :is-selected="isSelected('familiar_yoga', 'Yes')"
                  @click="saveAndNavigate('familiar_yoga', 'Yes', '/Body-Type-Have')" />
      <BaseOption text="Yes, I've tried it"
                  :img-src="
          isFemale
            ? require('../assets/images/yogaW.png')
            : require('../assets/images/tried.png')
        "
                  img-alt="Man"
                  is-single
                  :is-selected="isSelected('familiar_yoga', 'Tried')"
                  @click="saveAndNavigate('familiar_yoga', 'Tried', '/Body-Type-Have')" />
      <BaseOption text="No, I have never tried it"
                  :img-src="
          isFemale
            ? require('../assets/images/noYogaW.png')
            : require('../assets/images/never.png')
        "
                  img-alt="Man"
                  is-single
                  :is-selected="isSelected('familiar_yoga', 'No')"
                  @click="saveAndNavigate('familiar_yoga', 'No', '/Body-Type-Have')" />
    </div>
  </div>
</template>

<script setup>
  import BaseOption from "@/components/BaseOption.vue";
  import { guardNoCookie } from "@/logic/guardNoCookie";
  import { saveAndNavigate } from "@/logic/saveAndNavigate";
  import { updateProgressBar } from "@/logic/updateProgressBar";
  import { useProgress } from "@/logic/useProgress";
  import store from "@/store";
  import { computed, onMounted } from "vue";
  import { useSavedData } from "../store/savedDataStore";

  guardNoCookie();
  useProgress().set(4);

  const isFemale = computed(() => store.state.gender === "female");
  onMounted(() => {
    updateProgressBar();
    console.log(`${store.state.step}/${store.state.totalSteps}`);
  });

  const { isSelected } = useSavedData();
</script>
<style scoped>
  .options {
    margin: 32px 0 32px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
