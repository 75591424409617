import store from "@/store";

/** Updates the progress bar percentage. */
export function updateProgressBar() {
  const progressBarEl = document.getElementById("progressBar");
  if (progressBarEl === undefined) {
    console.error('No "progressBar" ID found in HTML.');
    return;
  }
  const progressPercentage =
    (store.state.step / (store.state.totalSteps - 1)) * 100;
  progressBarEl.style.width = `${progressPercentage}%`;
}
