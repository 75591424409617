import store from "@/store";
import { generateIdentifier } from "@/utils/generateIdentifier";
import { getCookie } from "@/utils/getCookie";
import { setCookie } from "@/utils/setCookie";

export function setCookieAndUID() {
  const cookieName = "MID";

  let identifier = getCookie(cookieName);

  if (!identifier) {
    identifier = generateIdentifier();
    setCookie(cookieName, identifier, 365); // Nastaví cookie na 1 rok
    console.log(`Created MID: ${identifier}`);
  } else {
    console.log(`Retrieved MID: ${identifier}`);
  }

  store.commit("setUID", identifier);
}
