<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>When was the last time you were happy with your body image?</h2>
    <div class="options">
      <BaseOption
text="Less than a year ago"
                  :img-src="require('../assets/images/thinking.svg')"
                  img-alt="Man"
                  is-single
                  :is-selected="isSelected('last_time_happy','1')"
                  :img-size="60"
                  @click="saveAndNavigate('last_time_happy', '1', '/Activity-Level')" />
      <BaseOption
text="1 to 2 years ago"
                  :img-src="require('../assets/images/grinning.svg')"
                  img-alt="Man"
                  is-single
                  :is-selected="isSelected('last_time_happy','2')"
                  :img-size="60"
                  @click="saveAndNavigate('last_time_happy', '2', '/Activity-Level')" />
      <BaseOption
text="More than 3 years ago"
                  :img-src="require('../assets/images/smilingTear.svg')"
                  img-alt="Man"
                  is-single
                  :is-selected="isSelected('last_time_happy','3')"
                  :img-size="60"
                  @click="saveAndNavigate('last_time_happy', '3', '/Activity-Level')" />
      <BaseOption
text="I am happy with my body image"
                  :img-src="require('../assets/images/smiling.svg')"
                  img-alt="Man"
                  is-single
                  :is-selected="isSelected('last_time_happy','-1')"
                  :img-size="60"
                  @click="saveAndNavigate('last_time_happy', '-1', '/Activity-Level')" />
      <BaseOption
text="Never"
                  :img-src="require('../assets/images/smilingTear.svg')"
                  img-alt="Man"
                  is-single
                  :is-selected="isSelected('last_time_happy','99')"
                  :img-size="60"
                  @click="saveAndNavigate('last_time_happy', '99', '/Activity-Level')" />
    </div>
  </div>
</template>

<script setup>
import BaseOption from "@/components/BaseOption.vue";
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { onMounted } from "vue";
import { useSavedData } from "../store/savedDataStore";
guardNoCookie();
useProgress().set(8);
const { isSelected } = useSavedData();

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
</script>
<style scoped>
.options {
  margin: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>