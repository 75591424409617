<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>Choose your current body type</h2>
        <div class="options">
      <BaseOption
        text="Regular"
        :img-src="isFemale ? require('../assets/images/regularW.png') : require('../assets/images/regular.png')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('body_type_have','Regular')"
        @click="saveAndNavigate('body_type_have', 'Regular', '/Body-Type-Want')"
      />
      <BaseOption
        text="Flabby"
        :img-src="isFemale ? require('../assets/images/flabbyW.png') : require('../assets/images/flabby.png')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('body_type_have','Flabby')"
        @click="saveAndNavigate('body_type_have', 'Flabby', '/Body-Type-Want')"
      />
      <BaseOption
        text="Extra"
        :img-src="isFemale ? require('../assets/images/fatW.png') : require('../assets/images/extra.png')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('body_type_have','Extra')"
        @click="saveAndNavigate('body_type_have', 'Extra', '/Body-Type-Want')"
      />
    </div>
  </div>
</template>

<script setup>
import BaseOption from "@/components/BaseOption.vue";
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { computed, onMounted } from "vue";
import { useSavedData } from "../store/savedDataStore";
guardNoCookie();
useProgress().set(5);
const isFemale = computed(() => store.state.gender === "female");
onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});

const { isSelected } = useSavedData();
</script>
<style scoped>
.options {
  margin: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>