<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>What is your yoga level?</h2>
          <div class="options">
      <BaseOption
        text="Beginner"
        description="I've never practiced yoga"
        :img-src="require('../assets/images/low.svg')"
        img-alt="Man"
        :img-size="60"
        is-single
        :is-selected="isSelected('yoga_level','Beginner')"
        @click="saveAndNavigate('yoga_level', 'Beginner', '/Touch-The-Floor')"
      />
      <BaseOption
        text="Experienced Beginner"
        description="I've had a few introductory classes"
        :img-src="require('../assets/images/mid.svg')"
        img-alt="Man"
        :img-size="60"
        is-single
        :is-selected="isSelected('yoga_level','ExpBeg')"
        @click="saveAndNavigate('yoga_level', 'ExpBeg', '/Touch-The-Floor')"
      />
      <BaseOption
        text="Intermediate"
        description="I've trained regularly before, but for a short time"
        :img-src="require('../assets/images/high.svg')"
        img-alt="Man"
        :img-size="60"
        is-single
        :is-selected="isSelected('yoga_level','Intermediate')"
        @click="saveAndNavigate('yoga_level', 'Intermediate', '/Touch-The-Floor')"
      />
      <BaseOption
        text="Advanced"
        description="I'm a pro and ready for the hardest poses"
        :img-src="require('../assets/images/full.svg')"
        img-alt="Man"
        :img-size="60"
        is-single
        :is-selected="isSelected('yoga_level','Advanced')"
        @click="saveAndNavigate('yoga_level', 'Advanced', '/Touch-The-Floor')"
      />
    </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { onMounted } from "vue";
import BaseOption from "../components/BaseOption.vue";
import { useSavedData } from "../store/savedDataStore";
guardNoCookie();
useProgress().set(12);
const { isSelected } = useSavedData();

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
</script>
<style scoped>
.options {
  margin: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>