<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    {{ isMobile }}
    <h2>Thank You for Choosing FitSpire!</h2>
    <div>
      <img src="../assets/images/users.png" alt="Our Users" class="image" />
    </div>
    <div>Please download the client app and log in to begin training.</div>
    <div v-if="isMobile" class="stores">
      <a
        href="https://play.google.com/store/apps/details?id=com.everfit&pcampaignid=web_share"
      >
        <img src="../assets/images/googlePlay.png" width="240px" />
      </a>
      <a href="https://apps.apple.com/us/app/everfit-train-smart/id1438926364">
        <img src="../assets/images/appStore.png" width="240px" />
      </a>
    </div>
    <div v-else class="stores">
      <img src="../assets/images/downloadQR.png" width="240px" />
    </div>
    <div class="btns">
      <BaseButton :width="300" @click="openStripeManageSubscription"
        >Manage Subscription</BaseButton
      >
      <a style="width: 300px" @click="router.push('/Logout')">
        <BaseButton :width="300">LOG OUT</BaseButton>
      </a>
    </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
//import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import BaseButton from "../components/BaseButton.vue";
import { getToken } from "../utils/useAuth";
const router = useRouter();

guardNoCookie();
useProgress().set(0);
//updateProgressBar();

const isMobile = computed(() => {
  return window.innerWidth <= 768;
});

async function openStripeManageSubscription() {
  try {
    // Replace `/api/stripe/session` with your actual backend endpoint
    const response = await fetch(
      `/api/Stripe/create-customer-portal-session?auth=${encodeURIComponent(
        getToken()
      )}`,
      {
        method: "GET", // or 'POST' if you're sending data like a customer ID
        headers: {
          "Content-Type": "application/json",
          // Include any necessary headers
        },
        // body: JSON.stringify({ customerId: 'yourCustomerId' }) // If needed
      }
    );
    const data = await response.json();

    if (data.url) {
      // Checks if data.url is truthy (i.e., it exists and is not an empty string)
      window.location.href = data.url;
    } else {
      console.log("No URL received to redirect to Stripe Customer Portal.");
    }
  } catch (error) {
    console.error("Error opening Stripe manage subscription:", error);
  }
}

onMounted(() => {
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
</script>
<style scoped>
.image {
  width: 100%;
  max-width: 400px;
}

.stores {
  margin-top: 24px;
  margin-bottom: 12px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.btns {
  margin-top: 32px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
</style>
