<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <div style="display: flex; justify-content: center; width: 100%">
      <div class="options">
        <h2>What is your current weight?</h2>
        <BaseSwitch
          v-model="unit"
          first-value="lb"
          second-value="kg"
          first-text="Lbs"
          second-text="Kg"
        />
        <BaseNumberBox
          ref="numberInputEl"
          v-model="numberInput"
          :min="30"
          :max="450"
          :placeholder="`Weight in ${unit === 'lb' ? 'lbs' : 'kg'}`"
          error-message="Please enter a numeric value between 30 and 450."
          @update:is-valid="(isValid) => (isNumberValid = isValid)"
        />

        <BaseButton
          :disabled="!isNumberValid"
          @click="saveAndNavigate('weight', dataToSend, '/Perfect-Weight')"
          >CONTINUE</BaseButton
        >
      </div>
      <img
        :src="
          isFemale
            ? require('../assets/images/sideWeightW.png')
            : require('../assets/images/sideWeight.png')
        "
        alt="Activity"
        class="side-image"
      />
    </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { computed, onMounted, ref } from "vue";
import BaseButton from "../components/BaseButton.vue";
import BaseNumberBox from "../components/BaseNumberBox.vue";
import BaseSwitch from "../components/BaseSwitch.vue";

guardNoCookie();
useProgress().set(21);

const numberInput = ref();
const unit = ref("kg");

if (
  store.state.country === "US" ||
  store.state.country === "GB" ||
  store.state.country === ""
)
  unit.value = "lb";

const isFemale = computed(() => store.state.gender === "female");

const isNumberValid = ref(false);

// function setUnit(newUnit) {
//   unit.value = newUnit;
// }
const dataToSend = computed(() => {
  store.state.weight = numberInput.value;
  store.state.weight_unit = unit.value;
  return `${numberInput.value} ${unit.value}`;
});

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
  if (store.state.weight !== "") {
    numberInput.value = store.state.weight;
    unit.value = store.state.weight_unit;
  }
});
</script>

<style scoped>
.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side-image {
  width: 200px;
  position: absolute;
  right: 220px;
  top: 490px;
  @media (max-width: 1200px) {
    display: none;
  }
}
</style>
