<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>The one and only plan you'll ever need to get in shape</h2>
    <div style="display: flex; justify-content: center; margin-bottom: 32px">
      <!-- <BaseInfo
        :title="`Get ready to amaze everyone`"
        :text="`According to the to the information you have provided us, you'll achieve your goal weight of ${goal}${goal_unit} by ${formattedDate}`"
      /> -->
      <div>
        <p>According to the to the information you have provided us,</p>
        <p>you'll be</p>
        <p style="margin-top: -10px">
          <span class="largeText">{{ goal }} {{ goal_unit }}</span> by
          <span class="largeText">{{ formattedDate }}</span>
        </p>

        <p v-if="!isNoEvent">and lose</p>
        <div
          v-if="!isNoEvent"
          style="display: flex; justify-content: center; align-items: center"
        >
          <div class="eventPanel">
            <p>
              <strong>~{{ lost_weight }} {{ goal_unit }}</strong> by the
              <strong>{{ spec_occas }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="chart">
        <apexchart type="line" :options="chartOptions" :series="chartSeries" />
      </div>
      <p class="disclaimer">
        Based on data from users who log their progress in the app. The chart is
        a general illustration, and individual results may vary.
      </p>
    </div>
    <BaseButton
      style="margin-top: 32px"
      @click="saveAndNavigate('continue', '', '/Creating-Plan')"
    >
      CONTINUE
    </BaseButton>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import BaseButton from "../components/BaseButton.vue";

const chartOptions = ref({
  chart: {
    type: "line",
    dropShadow: {
      enabled: true,
      color: "#000",
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2,
    },
    toolbar: {
      show: false,
    },
  },

  annotations: {
    points: [
      {
        x: "Now",
        y: 90,
        marker: {
          size: 0,
          fillColor: "#fff",
          strokeColor: "fff",
          radius: 0,
          cssClass: "apexcharts-custom-class",
        },
      },
      {
        x: "Event",
        y: 72,
        marker: {
          size: 8,
          fillColor: "#fff",
          strokeColor: "#ccc",
          strokeOpacity: 0.1,
          strokeWidth: 3,
          radius: 4,
          cssClass: "apexcharts-custom-class",
        },
        label: {
          borderColor: "#4263eb",
          offsetY: -20,
          offsetX: -20,
          style: {
            color: "#fff",
            background: "#4263eb",
          },

          text: "Goal Weight",
        },
      },
    ],
    xaxis: [],
  },

  colors: ["#4263eb", "#545454"],
  dataLabels: {
    enabled: false,
  },
  stroke: { width: 9, curve: "smooth" },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      gradientToColors: ["#FF0000", "#FFFF00", "#00FF00"],
      shadeIntensity: 1,
      type: "vertical",
      opacityFrom: 0.9,
      opacityTo: 0.9,
      stops: [10, 50, 100],
    },
  },
  title: {
    text: "Workout plan illustration",
    align: "left",
  },
  grid: {
    borderColor: "#e7e7e7",
    row: {},
  },
  markers: {
    size: 0,
  },
  xaxis: {
    categories: [],
    title: {
      text: "Weeks",
    },
  },
  yaxis: {
    title: {
      text: "Weight",
    },
    min: 60,
    max: 120,
  },
});

const chartSeries = ref([
  {
    name: "",
    data: [],
  },
]);
const goal = ref("");
const goal_unit = ref("");
const event_date = ref("");
const goal_date = ref("");
const spec_occas = ref("");
const lost_weight = ref("");

guardNoCookie();
useProgress().set(27);

axios
  .get(`/api/VarStore/GetChartData?sid=${store.state.uid}`)
  .then((response) => {
    const data = response.data;
    chartSeries.value[0].data = data.values;

    chartOptions.value = {
      ...chartOptions.value,
      xaxis: { ...chartOptions.value.xaxis, categories: data.labels },
    };

    chartOptions.value.yaxis.min = data.min;
    chartOptions.value.yaxis.max = data.max;

    chartOptions.value.annotations.points[0].y = data.weight;
    chartOptions.value.annotations.points[1].y = data.goal;
    chartOptions.value.annotations.points[1].x =
      chartOptions.value.xaxis.categories[
        chartOptions.value.xaxis.categories.length - 1
      ];
    chartOptions.value.xaxis.title.text = data.period;
    event_date.value = data.event_date;
    goal.value = data.goal;
    goal_unit.value = data.goal_unit;
    goal_date.value = data.goal_date;
    spec_occas.value = data.spec_occas;
    lost_weight.value = (data.weight - data.event_weight).toFixed(2);

    chartOptions.value.annotations.xaxis.push({
      x: isNoEvent.value
        ? ""
        : data.period === "Weeks"
        ? convertDateToWeekLabel(data.event_date)
        : convertDateToMonthLabel(data.event_date),
      borderColor: "#4263eb",
      strokeDashArray: 4,
      label: {
        orientation: "horizontal",
        offsetY: 20,
        borderColor: "#4263eb",
        style: {
          color: "#fff",
          background: "#4263eb",
        },
        text: `${data.spec_occas} - ${data.event_weight}${data.goal_unit}`,
      },
    });

    function convertDateToWeekLabel(date) {
      const startDate = new Date();
      const eventDate = new Date(date);

      // Calculate the difference in time
      const timeDiff = eventDate - startDate;

      // Calculate the number of weeks
      const weeksDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24 * 7));

      return `Week ${weeksDiff}`;
    }

    function convertDateToMonthLabel(date) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const [month, ,] = date.split("/");
      return months[parseInt(month - 1)];
    }
  })
  .catch((error) => {
    console.error("Došlo k chybě při načítání dat:", error);
  });

const formattedDate = computed(() => {
  if (!goal_date.value) return;
  const datePart = goal_date.value.split("T")[0];

  const date = new Date(datePart);
  if (
    store.state.country === "US" ||
    store.state.country === "GB" ||
    store.state.country === ""
  ) {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  } else {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }
});

const isNoEvent = computed(
  () => !spec_occas.value || spec_occas.value === "no"
);

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
</script>

<style scoped>
.chart {
  width: 100%;
  max-width: 600px;
  max-height: 400px;
  height: 100%;
}

.largeText {
  color: #4263eb;
  font-size: 24px;
  font-weight: 500;
}
.eventPanel {
  padding: 8px 16px;
  border-radius: 8px;
  width: fit-content;
  background-color: rgba(205, 205, 205, 0.35);
  p {
    margin: 0;
  }
}

.disclaimer {
  max-width: 600px;
  font-size: 12px;
  text-align: center;
  margin-top: 0px;
  color: #6c757d;
}
</style>
