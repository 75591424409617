import store from "@/store";
import { SYSVER } from '../config'

export function saveOnly(varname, data, step, actualURL) {
  console.log("sending:", data);
  const payload = {
    name: varname,
    value: data,
    step: step,
    uid: store.state.uid,
    lpv: store.state.lpv,
    cr: store.state.cr,
    hr: store.state.hr,
    tms: store.state.utm_source,
    tmm: store.state.utm_medium,
    tmc: store.state.utm_campaign,
    tmn: store.state.utm_content,
    baseURL: store.state.baseURL,
    sysver: SYSVER,
    nextURL: actualURL,
  };

  fetch("/api/varstore", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .catch((error) => {
      console.error("Error:", error);
    });
}
