<script setup>
import store from "@/store";
import axios from "axios";
import { computed, ref } from 'vue';
import { useRouter } from "vue-router";
import BaseButton from '../components/BaseButton.vue';
import BaseEmailBox from '../components/BaseEmailBox.vue';
import BaseTextInput from '../components/BaseTextInput.vue';

const router = useRouter();

const emailInput = ref();
const isValidEmail = ref(false);
const fullName = ref();
const isValidFullName = ref(false);
const password = ref();
const isValidPassword = ref(false);
const confirmPassword = ref();
const isValidConfirmPassword = ref(false);

const passwordError = computed(() => {
    return password.value && confirmPassword.value && password.value !== confirmPassword.value;
});

async function register(){
    try {
    await axios.post('/api/VarStore/Register', {
      email: emailInput.value,
      password: password.value,
      fullName: fullName.value,
      uid: store.state.uid
    });
    router.push('/Login');

  } catch (error) {
    console.error('Registration error:', error);
  }
}

  (() => {
    axios
      .get(`/api/VarStore/Session?sid=${store.state.uid}`)
      .then((response) => {
        emailInput.value = response.data.your_email;
      })
      .catch((error) => {
        console.error('Session error:', error);
      })
  })();


</script>
<template>
<h1>Finish registration to <span class="colored">save your progress</span> and access the plan</h1>
<form>
    <div style="display: flex; justify-content: center;">
    <p class="field">Full Name</p>
    </div>
    <BaseTextInput
    v-model="fullName"
    :width="600"
    placeholder="Enter your full name"
    error-message="Kindly provide your full name."
    @update:is-valid="isValidFullName = $event"
    />
    <div style="display: flex; justify-content: center;">
    <p class="field">Email</p>
    </div>
    <BaseEmailBox
    v-model="emailInput"
    :width="600"
      placeholder="Enter your email address."
      error-message="Kindly provide a valid email address."
      @update:is-valid="isValidEmail = $event"
    />
    <div style="display: flex; justify-content: center;">
    <p class="field">Password</p>
    </div>
    <BaseTextInput
    v-model="password"
    :width="600"
    is-password
    placeholder="Enter your password"
    error-message="Password must be at least 3 characters long."
    @update:is-valid="isValidPassword = $event"
    />
        <div style="display: flex; justify-content: center;">
    <p class="field">Confirm Password</p>
    </div>
    <BaseTextInput
    v-model="confirmPassword"
    :width="600"
    is-password
    placeholder="Confirm password"
    error-message="Password must be at least 3 characters long."
    @update:is-valid="isValidConfirmPassword = $event"
    />
    <p v-if="passwordError" class="error">Passwords dont match.</p>

    <BaseButton
        :disabled="!isValidEmail || !isValidFullName || !isValidPassword || !isValidConfirmPassword || passwordError"
        @click="register">
        CREATE MY ACCOUNT
    </BaseButton>
</form>
</template>

<style scoped>
.error {
    color: red;
    font-size: 16px;
    margin-top: 0;
}
.colored {
    color: #4263eb;
}
.field {
    font-size: 16px;
    font-weight: lighter;
    color: #495057;
    margin: 0;
    width: 100%;
    max-width: 600px;
    text-align: left;
}
</style>