<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>Select your age.</h2>
    <div class="options">
      <BaseTile
        text="25-34"
        :img-src="
          isFemale
            ? require('../assets/images/woman.png')
            : require('../assets/images/youngMan.png')
        "
        img-alt="Man 25-34"
        :is-selected="isSelected('age_group', '25-34')"
        @click="saveAndNavigate('age_group', '25-34', '/Select-Goals')"
      />
      <BaseTile
        text="35-44"
        :img-src="
          isFemale
            ? require('../assets/images/25W.png')
            : require('../assets/images/man.png')
        "
        img-alt="Man 35-44"
        :is-selected="isSelected('age_group', '35-44')"
        @click="saveAndNavigate('age_group', '35-44', '/Select-Goals')"
      />
      <BaseTile
        text="45-54"
        :img-src="
          isFemale
            ? require('../assets/images/40W.png')
            : require('../assets/images/man40.png')
        "
        img-alt="Man 45-54"
        :is-selected="isSelected('age_group', '45-54')"
        @click="saveAndNavigate('age_group', '45-54', '/Select-Goals')"
      />
      <BaseTile
        text="55-64"
        :img-src="
          isFemale
            ? require('../assets/images/45W.png')
            : require('../assets/images/midMan.png')
        "
        img-alt="Man 55-64"
        :is-selected="isSelected('age_group', '55-64')"
        @click="saveAndNavigate('age_group', '55-64', '/Select-Goals')"
      />
      <BaseTile
        text="65+"
        :img-src="
          isFemale
            ? require('../assets/images/60W.png')
            : require('../assets/images/agedMan.png')
        "
        img-alt="Man 65+"
        :is-selected="isSelected('age_group', '65+')"
        @click="saveAndNavigate('age_group', '65+', '/Select-Goals')"
      />
    </div>
  </div>
</template>

<script setup>
import BaseTile from "@/components/BaseTile.vue";
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { computed, onMounted } from "vue";
import { useSavedData } from "../store/savedDataStore";

guardNoCookie();
useProgress().set(1);

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
const { isSelected } = useSavedData();

const isFemale = computed(() => store.state.gender === "female");
</script>

<style scoped>
.options {
  display: flex;
  justify-content: center;
  margin-top: 36px;
  flex-wrap: wrap;
  gap: 20px;

  @media screen and (max-width: 768px) {
    gap: 12px;
    margin-top: 24px;
  }
}
</style>
