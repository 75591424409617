<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <img src="../assets/images/logo.png" alt="logo" width="160px" />
    <h1>{{appName}}</h1>

    <p>2-minute quiz</p>

    <p>First, please select your gender.</p>
    <div class="selection">
      <div class="options">
        <img src="../assets/images/man.png" alt="Man" width="260" />
        <BaseButton style="height: 48px"
                    class="btn"
                    :width="260"
                    @click="
            saveAndNavigate('gender', 'male', '/Select-Your-Age');
            store.commit('setGender', 'male');
          ">MALE</BaseButton>
      </div>
      <div class="options">
        <img src="../assets/images/woman.png" alt="Woman" width="260" />
        <BaseButton style="height: 48px"
                    :width="260"
                    class="btn"
                    @click="
            saveAndNavigate('gender', 'female', '/Select-Your-Age');
            store.commit('setGender', 'female');
          ">FEMALE</BaseButton>
      </div>
    </div>
    <div class="smallText">
      By continuing, you confirm that you have read, understood, and agreed to
      our
      <a class="linkText" href="/Terms-of-Service" target="_blank">Terms of Use</a>,
      <a class="linkText" href="/Privacy-Policy" target="_blank">Privacy Policy</a>
      and
      <a class="linkText" target="_blank" href="/Refund-Policy">Refund Policy</a>.<br />
      <RouterLink to="/Support-Ticket" class="divider link">Support</RouterLink>
      | <RouterLink to="/Login" class="link">Login</RouterLink>
    </div>
  </div>
</template>

<script setup>
import BaseButton from "@/components/BaseButton.vue";
import { gtagEvent } from "@/logic/gtagEvent";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { saveOnly } from "@/logic/saveOnly";
import { setCookieAndUID } from "@/logic/setCookieAndUID";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import axios from "axios";
import { ref,onMounted } from "vue";
import { useRoute } from "vue-router";

//<div class="actions">
//  <RouterLink to="/Support-Ticket" class="divider link">Support</RouterLink>
//  <RouterLink to="/Login" class="link">Login</RouterLink>
  //</div>

  //console.log(process.env.VUE_APP_URL);

setCookieAndUID();
useProgress().set(0);
  const route = useRoute();

  const appName = ref(process.env.VUE_APP_NAME);

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
  const lpv = route.query.lpv;
  if (lpv) {
    store.commit("setLpv", lpv);
  }

  const cr = route.query.cr;
  if (cr) {
    store.commit("setCR", cr);
  }

  const hr = document.referrer;
  if (hr) {
    store.commit("setHR", hr);
  }

  const utm_source = route.query.utm_source;
  if (utm_source) {
    store.commit("setSource", utm_source);
  } else {
    const utm_source = route.query.s;
    if (utm_source) {
      store.commit("setSource", utm_source);
    }
  }

  const utm_medium = route.query.utm_medium;
  if (utm_medium) {
    store.commit("setMedium", utm_medium);
  } else {
    const utm_medium = route.query.m;
    if (utm_medium) {
      store.commit("setMedium", utm_medium);
    }
  }

  const utm_campaign = route.query.utm_campaign;
  if (utm_campaign) {
    store.commit("setCampaign", utm_campaign);
  } else {
    const utm_campaign = route.query.c;
    if (utm_campaign) {
      store.commit("setCampaign", utm_campaign);
    }
  }

  const utm_content = route.query.utm_content;
  if (utm_content) {
    store.commit("setContent", utm_content);
  } else {
    const utm_content = route.query.n;
    if (utm_content) {
      store.commit("setContent", utm_content);
    }
  }

  saveOnly("start", "", -1,'/Select-Your-Gender');

  if (store.state.country === "") {
    axios
      .get(`/api/VarStore/Session?sid=${store.state.uid}`)
      .then((response) => {
        if (response.data.ipcountry !== null)
          store.state.country = response.data.ipcountry;
        console.log(`ipcountry=${store.state.country}`);
      })
      .catch((error) => {
        console.error("Došlo k chybě při načítání dat:", error);
      });
  }

  gtagEvent("view_item", "");
});
</script>
<style scoped>
.selection {
  display: flex;
  justify-content: center;
  margin-top: 36px;
  gap: 120px;
}
.options {
  display: flex;
  flex-direction: column;
  max-width: 260px;
}
@media (max-width: 768px) {
  .selection {
    gap: 12px;
  }

  .options img,
  .options BaseButton {
    width: 100%;
    max-width: 160px;
  }

  .options {
    max-width: 100%;
  }
}
.smallText {
  margin-top: 36px;
  font-size: x-small;
}
.linkText {
  text-transform: capitalize;
  text-decoration: none;
  color: #4263eb;
}
.actions {
  position: absolute;
  top: 24px;
  right: 16px;
  .link {
    text-decoration: none;
    color: #4263eb;
    text-transform: uppercase;
    padding: 2px 8px;
  }
  .divider {
    border-right: 1px solid #4263eb;
  }
}
</style>
