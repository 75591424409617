import { router } from "@/router";
import store from "@/store";
import { useSavedData } from "../store/savedDataStore";
import { SYSVER } from '../config'

export function saveAndNavigate(varname, data, nextUrl) {
  console.log("sending:", data);
  // router.push({ path: nextUrl });
  useSavedData().saveData(varname, data);
  const payload = {
    name: varname,
    value: data,
    step: store.state.step,
    uid: store.state.uid,
    lpv: store.state.lpv,
    cr: store.state.cr,
    hr: store.state.hr,
    tms: store.state.utm_source,
    tmm: store.state.utm_medium,
    tmc: store.state.utm_campaign,
    tmn: store.state.utm_content,
    baseURL: store.state.baseURL,
    sysver: SYSVER,
    nextURL: nextUrl,
  };

  fetch("/api/varstore", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (response.ok) {
        router.push({ path: nextUrl });
      } else {
        console.error("Error:", response.statusText);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
