<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <div style="display: flex; justify-content: center; width: 100%">
      <div class="options">
        <BaseInfo
          style="margin-bottom: 32px"
          :title="`Your BMI is ${bmi}, which is considered ${bmi_desc}`"
          text="You should pay more attention to your weight. We will use your index to tailor a weight loss program."
        />
        <h2>What would you consider your perfect weight?</h2>
        <BaseSwitch
          v-model="unit"
          first-value="lb"
          second-value="kg"
          first-text="Lbs"
          second-text="Kg"
        />
        <BaseNumberBox
          v-model="numberInput"
          :min="30"
          :max="450"
          :placeholder="`Weight in ${unit === 'lb' ? 'lbs' : 'kg'}`"
          error-message="Please enter a numeric value between 30 and 450."
          @update:is-valid="(isValid) => (isNumberValid = isValid)"
        />
        <BaseButton
          :disabled="!isNumberValid"
          @click="saveAndNavigate('goal_weight', dataToSend, '/Your-Age')"
          >CONTINUE</BaseButton
        >
      </div>
      <img
        :src="
          isFemale
            ? require('../assets/images/sideWeightWantW.png')
            : require('../assets/images/sideWeightWant.png')
        "
        alt="Activity"
        class="side-image"
      />
    </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import BaseButton from "../components/BaseButton.vue";
import BaseInfo from "../components/BaseInfo.vue";
import BaseNumberBox from "../components/BaseNumberBox.vue";
import BaseSwitch from "../components/BaseSwitch.vue";

guardNoCookie();
useProgress().set(22);

const numberInput = ref();
const unit = ref("kg");
if (
  store.state.country === "US" ||
  store.state.country === "GB" ||
  store.state.country === ""
)
  unit.value = "lb";

const loading = ref(true);
const userData = ref({});
const bmi = ref(0.0);
const bmi_desc = ref("");

(() => {
  loading.value = true;
  axios
    .get(`/api/VarStore/Session?sid=${store.state.uid}`)
    .then((response) => {
      userData.value = response.data;
      unit.value = response.data.weight_unit;
      bmi.value = response.data.bmi;
      loading.value = false;
      console.log(bmi);
      if (bmi.value <= 18.5) bmi_desc.value = "underweight";
      else if (bmi.value <= 25) bmi_desc.value = "normal";
      else if (bmi.value <= 30) bmi_desc.value = "overweight";
      else bmi_desc.value = "obesity";
    })
    .catch((error) => {
      console.error("Došlo k chybě při načítání dat:", error);
      loading.value = false;
    });
})();

const isNumberValid = ref(false);

const dataToSend = computed(() => {
  store.state.goal_weight = numberInput.value;
  store.state.goal_weight_unit = unit.value;
  return `${numberInput.value} ${unit.value}`;
});

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
  if (store.state.goal_weight !== "") {
    numberInput.value = store.state.goal_weight;
    unit.value = store.state.goal_weight_unit;
  }
});
const isFemale = computed(() => store.state.gender === "female");
</script>
<style scoped>
.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side-image {
  width: 200px;
  position: absolute;
  right: 220px;
  top: 600px;
  @media (max-width: 1200px) {
    display: none;
  }
}
</style>
