<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>Is there a special occasion you want to lose weight for?</h2>
    <p>
      You're more likely to reach your goal if you have something important to
      aim for
    </p>
    <div class="options">
      <BaseOption
        :img-size="40"
        text="Vacation"
        :img-src="require('../assets/images/plane.svg')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('spec_occas', 'vacation')"
        @click="saveAndNavigate('spec_occas', 'vacation', '/Your-Event')"
      />
      <BaseOption
        :img-size="40"
        text="Wedding"
        :img-src="require('../assets/images/celebrate.svg')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('spec_occas', 'wedding')"
        @click="saveAndNavigate('spec_occas', 'wedding', '/Your-Event')"
      />
      <BaseOption
        :img-size="40"
        text="Sporting event"
        :img-src="require('../assets/images/trophy.svg')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('spec_occas', 'sporting event')"
        @click="saveAndNavigate('spec_occas', 'sporting event', '/Your-Event')"
      />
      <BaseOption
        :img-size="40"
        text="Beach trip"
        :img-src="require('../assets/images/beach.svg')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('spec_occas', 'beach trip')"
        @click="saveAndNavigate('spec_occas', 'beach trip', '/Your-Event')"
      />
      <BaseOption
        :img-size="40"
        text="Reunion"
        :img-src="require('../assets/images/party.svg')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('spec_occas', 'reunion')"
        @click="saveAndNavigate('spec_occas', 'reunion', '/Your-Event')"
      />
      <BaseOption
        :img-size="40"
        text="Family occasion"
        :img-src="require('../assets/images/family.svg')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('spec_occas', 'family occasion')"
        @click="saveAndNavigate('spec_occas', 'family occasion', '/Your-Event')"
      />
      <BaseOption
        :img-size="40"
        text="Other"
        :img-src="require('../assets/images/grinning.svg')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('spec_occas', 'event')"
        @click="saveAndNavigate('spec_occas', 'event', '/Your-Event')"
      />
      <BaseOption
        :img-size="40"
        text="No"
        :img-src="require('../assets/images/no.svg')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('spec_occas', 'no')"
        @click="saveAndNavigate('spec_occas', 'no', '/One-Only-Plan')"
      />
    </div>
  </div>
</template>

<script setup>
import BaseOption from "@/components/BaseOption.vue";
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { onMounted } from "vue";
import { useSavedData } from "../store/savedDataStore";

guardNoCookie();
useProgress().set(25);
const { isSelected } = useSavedData();

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
</script>
<style scoped>
.options {
  margin: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
