<script setup>
import { gtagEvent } from "@/logic/gtagEvent";
import { guardNoCookie } from "@/logic/guardNoCookie";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import axios from "axios";
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watchEffect,
} from "vue";
import { useRouter } from "vue-router";
import BaseButton from "../components/BaseButton.vue";
import BaseQuestion from "../components/BaseQuestion.vue";
import DidYouKnow from "../components/DidYouKnow.vue";
import PlanOption from "../components/PlanOption.vue";
import { SYSVER } from "../config";
import { useSavedData } from "../store/savedDataStore";

// swiper imports

import "swiper/css/effect-cube";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectCube, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper and modules
// import Swiper from "swiper";

// // Import Swiper styles
import "swiper/css/bundle";

// import "swiper/css/effect-cube";
// import "swiper/css/pagination";

// // import required modules
// import { EffectCube, Pagination } from "swiper/modules";

// import("vue").defineComponent({
//   components: {
//     Swiper,
//     SwiperSlide,
//   },
//   setup() {
//     return {
//       modules: [EffectCube, Pagination],
//     };
//   },
// });

const appSport = ref(process.env.VUE_APP_SPORT_LOW);
const appName = ref(process.env.VUE_APP_NAME);

guardNoCookie();
useProgress().set(32);
const router = useRouter();

const loading = ref({});
const userData = ref({});
const offerData = ref({});
const act_level_desc = ref({});
const age_group = ref("");
const yoga_level = ref("");
const reastate = reactive({
  yoga_level: 2,
});
const body_fat_act = ref("");
const body_fat_dst = ref("");
const selectedPlan = ref("4Wdis");

const isSale = computed(() => {
  if (!saleValue.value) return false;
  const discountNumeric = parseInt(saleValue.value.replace("%", ""));
  return discountNumeric > 62;
});
const saleValue = computed(() => {
  if (!offerData.value?.offers) return "";
  return offerData.value.offers[0].discountPerc;
});

const showIframe = ref(false);
const isClosed = ref(false);

function handleButtonClick() {
  const payload = {
    name: "billing_plan",
    value: selectedPlan.value,
    step: store.state.step,
    uid: store.state.uid,
    lpv: store.state.lpv,
    cr: store.state.cr,
    hr: store.state.hr,
    tms: store.state.utm_source,
    tmm: store.state.utm_medium,
    tmc: store.state.utm_campaign,
    tmn: store.state.utm_content,
    baseURL: store.state.baseURL,
    sysver: SYSVER,
    nextURL: "/Plan-Purchase",
  };
  fetch("/api/varstore", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (response.ok) {
        showIframe.value = true;
        document.body.classList.add("no-scroll");
      } else {
        console.error("Error:", response.statusText);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

const endTime = ref(new Date(new Date().getTime() + 10 * 60 * 1000));

function closeIframe() {
  if (!isClosed.value) {
    isClosed.value = true;
    return;
  }
  showIframe.value = false;
  endTime.value = new Date(new Date().getTime() + 10 * 60 * 1000);
  document.body.classList.remove("no-scroll");

  axios
    .get(`/api/VarStore/Get-Plan-Offers?sid=${store.state.uid}&d=1`)
    .then((response) => {
      offerData.value = response.data;
      selectedPlan.value = offerData.value.offers.find(
        (offer) => offer.mostPopular
      ).planCode;
    })
    .catch((error) => {
      console.error("Došlo k chybě při načítání dat:", error);
    });
  isClosed.value = false;
}
const iframeElement = ref(null);
function handleIframeLoad() {
  const iframe = iframeElement.value;
  if (!iframe) return;
  const url = new URL(iframe.contentWindow.location.href);
  const pathname = url.pathname;
  if (pathname === "/Loading") {
    showIframe.value = false;
    router.push("/Thank-You");
  }
  if (pathname === "/Billing-Page") {
    showIframe.value = false;
    router.push("/Billing-Page");
  }
}

// const disclaimerText = computed(() => {
//   if (!offerData.value?.offers) return "";
//   return offerData.value?.offers.find(
//     (offer) => offer.planCode === selectedPlan.value
//   )?.planHint;
// });

(() => {
  loading.value = true;
  axios
    .get(`/api/VarStore/Session?sid=${store.state.uid}`)
    .then((response) => {
      userData.value = response.data;

      //this.unit = response.data.weight_unit;
      if (userData.value?.yoga_level === "Beginner") reastate.yoga_level = 1;
      else if (userData.value?.yoga_level === "ExpBeg") reastate.yoga_level = 2;
      else if (userData.value?.yoga_level === "Intermediate")
        reastate.yoga_level = 3;
      else if (userData.value?.yoga_level === "Advanced")
        reastate.yoga_level = 4;

      if (userData.value?.yoga_level === "Beginner")
        yoga_level.value = "Beginner";
      else if (userData.value?.yoga_level === "ExpBeg")
        yoga_level.value = "Experienced Beginner";
      else if (userData.value?.yoga_level === "Intermediate")
        yoga_level.value = "Intermediate";
      else if (userData.value?.yoga_level === "Advanced")
        yoga_level.value = "Advanced";

      if (userData.value?.activity_level === "1")
        act_level_desc.value = "Sedentary";
      else if (userData.value?.activity_level === "2")
        act_level_desc.value = "Somewhat active";
      else if (userData.value?.activity_level === "3")
        act_level_desc.value = "Moderately active";
      else if (userData.value?.activity_level === "4")
        act_level_desc.value = "Very active";
      else if (userData.value?.activity_level === "5")
        act_level_desc.value = "Highly active";

      let formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
      age_group.value = userData.value?.age_group;
      body_fat_act.value = formatter.format(userData.value?.body_fat_act);
      body_fat_dst.value = formatter.format(userData.value?.body_fat_dst);
    })
    .catch((error) => {
      console.error("Došlo k chybě při načítání dat:", error);
      loading.value = false;
    });

  axios
    .get(`/api/VarStore/Get-Plan-Offers?sid=${store.state.uid}`)
    .then((response) => {
      offerData.value = response.data;
      loading.value = false;
    })
    .catch((error) => {
      console.error("Došlo k chybě při načítání dat:", error);
      loading.value = false;
    });
})();

const currentTime = ref(new Date());

// Update current time continuously
const updateCurrentTime = () => {
  currentTime.value = new Date();
};

function scrollToPlanButton() {
  const plan = document.getElementById("plan");
  if (plan) {
    plan.scrollIntoView({ behavior: "smooth" });
  }
}

watchEffect(() => {
  const intervalId = setInterval(updateCurrentTime, 1000);
  onUnmounted(() => clearInterval(intervalId));
});

const remainingTime = computed(() => {
  const timeLeft =
    (endTime.value.getTime() - currentTime.value.getTime()) / 1000;
  return Math.max(0, Math.round(timeLeft));
});
const formatedRemainingTime = computed(() => {
  const minutes = Math.floor(remainingTime.value / 60);
  const seconds = remainingTime.value % 60;
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
});

const isTimedOut = computed(() => {
  return remainingTime.value <= 0;
});
const isFemale = computed(() => store.state.gender === "female");
onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
  gtagEvent("add_to_cart", "");
});

const beforeImage = computed(() => {
  const age = useSavedData().data.value.age_group || age_group.value;
  if (age === "55-64" || age === "65+") {
    return isFemale.value
      ? require("../assets/images/f55before-2.png")
      : require("../assets/images/m55before.png");
  }
  if (age === "35-44" || age === "45-54") {
    return isFemale.value
      ? require("../assets/images/f35before.png")
      : require("../assets/images/m35before.png");
  }
  return isFemale.value
    ? require("../assets/images/f25before.png")
    : require("../assets/images/m25before.png");
});

const afterImage = computed(() => {
  const age = useSavedData().data.value.age_group || age_group.value;
  if (age === "55-64" || age === "65+") {
    return isFemale.value
      ? require("../assets/images/f55after.png")
      : require("../assets/images/m55after.png");
  }
  if (age === "35-44" || age === "45-54") {
    return isFemale.value
      ? require("../assets/images/f35after.png")
      : require("../assets/images/m35after.png");
  }
  return isFemale.value
    ? require("../assets/images/f25after.png")
    : require("../assets/images/m25after.png");
});

const userResultImageFirst = computed(() => {
  return isFemale.value
    ? require("../assets/images/res-before-after-1.png")
    : require("../assets/images/before-after-1-male.png");
});
const userResultImageSecond = computed(() => {
  return isFemale.value
    ? require("../assets/images/res-before-after-2.png")
    : require("../assets/images/before-after-2-male.png");
});
const userResultImageThird = computed(() => {
  return isFemale.value
    ? require("../assets/images/res-before-after-3.png")
    : require("../assets/images/before-after-3-male.png");
});

const userResultLabelFirst = computed(() => {
  return isFemale.value ? "Eva, ~8 lbs" : "Jim, ~13 lbs";
});
const userResultLabelSecond = computed(() => {
  return isFemale.value ? "Loren, ~10 lbs" : "Thomas, ~ 9 lbs";
});
const userResultLabelThird = computed(() => {
  return isFemale.value ? "Helena, ~13 lbs" : "Mike, ~ 14 lbs";
});

const userResultTextFirst = computed(() => {
  return isFemale.value
    ? "I never thought I could become flexible, but this app has proven me wrong. I’ve been using it to help relieve stress and increase my flexibility. Apart from that, I also feel more comfortable in my new weight. It’s exactly what I needed to improve my overall well-being!"
    : "As a retiree, staying active has been my top priority. This app has been incredible for keeping me fit and energetic. The workout routines are diverse and well-structured, giving me the motivation I need each day. It’s been a fantastic addition to my daily routine!";
});
const userResultTextSecond = computed(() => {
  return isFemale.value
    ? "I've struggled with joint pain for years, and this app is perfect for me. The yoga sessions are gentle on my body, and the workout programs are tailored to be effective without causing discomfort. Plus, I've lost around 10 lbs since I started using it. It’s been a fantastic addition to my routine!"
    : "Since I turned 50, I felt like I was losing my edge. This app changed everything. The variety of workouts keeps things fresh, and now I feel more energetic and youthful than I have in years. It's hands down the best decision I've made for my health!";
});
const userResultTextThird = computed(() => {
  return isFemale.value
    ? "Since retiring, I’ve had more time to focus on my health, and this app has been a wonderful companion. The yoga classes are soothing and help me stay flexible, while the workout routines keep me active. It also helped me lose weight, and I feel better than ever!"
    : "For years, I battled being overweight, but this app has been a true lifesaver. The workouts are tough but achievable, and the progress I've made in 2 months is astounding. I'm finally on my way to the body I've always dreamed of!";
});

const planButtonVisible = ref(true);

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      planButtonVisible.value = entry.isIntersecting;
    });
  },
  { root: null, threshold: 0.1 }
);

onMounted(() => {
  const planButton2 = document.getElementById("planButton2");
  if (planButton2) {
    observer.observe(planButton2);
  }
  const planButton1 = document.getElementById("planButton1");
  if (planButton1) {
    observer.observe(planButton1);
  }
});

onUnmounted(() => {
  const planButton1 = document.getElementById("planButton1");
  if (planButton1) {
    observer.unobserve(planButton1);
  }
  const planButton2 = document.getElementById("planButton2");
  if (planButton2) {
    observer.observe(planButton2);
  }
});
</script>

<template>
  <div id="step{{ $store.state.step }}" class="survey-step container-content">
    <div class="topButton" :class="[{ hidden: planButtonVisible }]">
      <div style="display: flex; gap: 4px; align-items: center">
        <p>Discount is reserved for:</p>
        <span class="time">{{ formatedRemainingTime }}</span>
      </div>
      <BaseButton
        color="#2FA177"
        :pulsating="!planButtonVisible"
        :width="150"
        @click="scrollToPlanButton"
        >GET MY PLAN</BaseButton
      >
    </div>
    <div class="centerTopButton" :class="[{ hidden: planButtonVisible }]">
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;
        "
      >
        <p>Discount is reserved for:</p>
        <p class="time">{{ formatedRemainingTime }}</p>
      </div>
      <BaseButton
        color="#2FA177"
        :pulsating="!planButtonVisible"
        :width="150"
        @click="scrollToPlanButton"
        >GET MY PLAN</BaseButton
      >
    </div>
    <div style="margin-bottom: 32px">
      <img src="../assets/images/logo.png" alt="logo" width="80px" />
    </div>

    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      "
    >
      <div class="bgbox">
        <div class="label">
          <div class="labelNow">
            <h3>Now</h3>
          </div>
          <div class="labelDivider" />
          <div class="labelGoal">
            <h3>Your Goal</h3>
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 100%;
          "
        >
          <img class="goalImage" :src="beforeImage" alt="now" />
          <div class="arrow">
            <img
              width="100%"
              src="../assets/images/offerRight.svg"
              alt="arrow"
            />
          </div>
          <img class="goalImage" :src="afterImage" alt="after" />
        </div>
      </div>
      <div style="display: flex; gap: 24px; margin: 24px 0px">
        <div>
          <strong><p style="margin-bottom: 12px">Body Fat</p></strong>
          <p class="value">{{ body_fat_act }}%</p>
          <strong> <p style="margin-bottom: 12px">Fitness Level</p></strong>
          <div class="yoga-level-indicator">
            <div
              v-for="level in 5"
              :key="level"
              class="yoga-level-block"
              :class="{ active: level <= reastate.yoga_level }"
            />
          </div>
        </div>
        <div class="labelDivider" />
        <div>
          <strong><p style="margin-bottom: 12px">Body Fat</p></strong>
          <p class="value">{{ body_fat_dst }}%</p>
          <strong> <p style="margin-bottom: 12px">Fitness Level</p></strong>
          <div class="yoga-level-indicator">
            <div
              v-for="level in 5"
              :key="level"
              class="yoga-level-block"
              :class="{ active: level <= 5 }"
            />
          </div>
        </div>
      </div>
    </div>

    <h2>YOUR {{ appName }} PLAN IS READY!</h2>
    <div style="display: flex; justify-content: center; align-items: center">
      <div class="targetWeight">
        <p class="targetHeading">Target weight</p>
        <strong style="font-size: 20px"
          >⚖️ {{ userData.goal_weight }} {{ userData.goal_weight_unit }}</strong
        >
      </div>
      <div>
        <p class="targetHeading">Starting level</p>
        <strong style="font-size: 20px"
          ><img src="../assets/images/signalMid.svg" /> {{ yoga_level }}</strong
        >
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center">
      <p class="offerEnds">
        🕓 This offer ends in {{ formatedRemainingTime }} min
      </p>
    </div>

    <div
      id="plan"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div v-if="isSale" class="extra">
        <p>
          🔥 Get your personal plan with up to
          <span
            style="color: rgb(45, 197, 106); font-size: 24px; font-weight: bold"
            >{{ saleValue }}</span
          >
          discount
        </p>
      </div>
      <div
        v-for="offer in offerData.offers"
        :key="offer.planCode"
        style="display: flex; justify-content: center; align-items: center"
      >
        <PlanOption
          style="width: 320px"
          :text="offer.planName"
          :price-per-day="offer.pricePerDay"
          :base-price-per-day="offer.basePricePerDay"
          :base-period-fee="offer.basePeriodFee"
          :period-fee="offer.periodFee"
          :is-selected="selectedPlan === offer.planCode"
          :is-popular="offer.mostPopular"
          :is-sale="!isTimedOut"
          @click="selectedPlan = offer.planCode"
        />
      </div>
      <p>30-DAY MONEY-BACK GUARANTEE</p>
      <BaseButton
        id="planButton1"
        color="#2FA177"
        pulsating
        :disabled="!selectedPlan"
        style="height: 80px; margin: 32px 0"
        @click="handleButtonClick()"
        >GET MY PLAN</BaseButton
      >
      <div v-if="showIframe" class="iframe-wrapper">
        <div class="iframe-container">
          <img
            src="../assets/images/close.svg"
            alt="Close"
            class="close-button"
            @click="closeIframe"
          />
          <iframe
            v-if="!isClosed"
            ref="iframeElement"
            src="/Plan-Purchase"
            width="100%"
            height="100%"
            style="border: none"
            @load="handleIframeLoad"
          />
          <DidYouKnow v-else @ok="closeIframe" />
        </div>
      </div>
      <!-- <p class="disclaimer">{{ disclaimerText }}</p> -->
    </div>
    <div class="container-payments">
      <h3>Guaranteed Safe Checkout</h3>
      <div class="images-payments">
        <img
          height="16px"
          :src="require('../assets/images/pay/visa-48.png')"
          alt="Visa"
        /><img
          height="20px"
          :src="require('../assets/images/pay/mc-48.png')"
          alt="MC"
        /><img
          height="20px"
          :src="require('../assets/images/pay/amex-48.png')"
          alt="Amex"
        /><img
          height="20px"
          :src="require('../assets/images/pay/discover-48.png')"
          alt="Discover"
        /><img
          height="20px"
          :src="require('../assets/images/pay/apple-48.png')"
          alt="Apple Pay"
        /><img
          height="20px"
          :src="require('../assets/images/pay/google-48.png')"
          alt="Google Pay"
        /><img
          height="20px"
          :src="require('../assets/images/pay/ssl-48.png')"
          alt="SSL"
        />
      </div>
    </div>

    <h2 style="margin: 48px 0px 32px 0px">
      Use Our Mobile App to Achieve your Goals
    </h2>
    <div class="appinfo">
      <!-- <div class="screen">
        <img src="../assets/images/screen.png" width="100%" />
      </div> -->
      <!-- Swiper V2 -->
      <Swiper
        :effect="'cube'"
        :grab-cursor="true"
        :centered-slides="true"
        :pagination="true"
        :navigation="true"
        :cube-effect="{
          shadow: true,
          slideShadows: false,
          shadowOffset: 4,
          shadowScale: 0.2,
        }"
        :modules="[EffectCube, Navigation, Pagination]"
        class="mySwiper"
      >
        <swiper-slide>
          <img src="../assets/images/appscreen-1.png" class="swiper-img" />
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/images/appscreen-2.png" class="swiper-img" />
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/images/appscreen-3.png" class="swiper-img" />
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/images/appscreen-4.png" class="swiper-img" />
        </swiper-slide>
      </Swiper>

      <div class="container-points">
        <div class="app-point">
          ✅
          <p style="margin: 0">
            Personalized workouts with video tutorials to suit your needs
          </p>
        </div>
        <div class="app-point">
          ✅
          <p style="margin: 0">Core strength exercises</p>
        </div>
        <div class="app-point">
          ✅
          <p style="margin: 0">Weight loss special programs</p>
        </div>
        <div class="app-point">
          ✅
          <p style="margin: 0">Tailored {{ appSport }} series</p>
        </div>
        <div class="app-point">
          ✅
          <p style="margin: 0">
            Step-by-step guidance with reminders and captivating progress charts
          </p>
        </div>
      </div>
    </div>
    <div class="container-results">
      <h2 class="heading-results">Amazing results from our users</h2>
      <!-- swiper results -->
      <swiper
        :slides-per-view="'auto'"
        :centered-slides="true"
        :loop="true"
        :space-between="30"
        :navigation="true"
        :pagination="{
          clickable: true,
        }"
        :modules="[Navigation, Pagination]"
        class="mySwiper"
      >
        <swiper-slide class="user-result">
          <img
            class="user-result-img"
            :src="userResultImageFirst"
            alt="progress"
          />
          <div class="user-result-info">
            <span class="info-lbs">{{ userResultLabelFirst }}</span>
            <p>
              {{ userResultTextFirst }}
            </p>
          </div>
        </swiper-slide>
        <swiper-slide class="user-result">
          <img
            class="user-result-img"
            :src="userResultImageSecond"
            alt="progress"
          />
          <div class="user-result-info">
            <span class="info-lbs">{{ userResultLabelSecond }}</span>
            <p>
              {{ userResultTextSecond }}
            </p>
          </div>
        </swiper-slide>
        <swiper-slide class="user-result">
          <img
            class="user-result-img"
            :src="userResultImageThird"
            alt="progress"
          />
          <div class="user-result-info">
            <span class="info-lbs">{{ userResultLabelThird }}</span>
            <p>
              {{ userResultTextThird }}
            </p>
          </div>
        </swiper-slide>
      </swiper>

      <div style="display: flex; justify-content: center">
        <p class="disclaimer">
          The displayed results are not necessarily representative and depend on
          various factors, including gender, age, physical condition, adherence
          to meal and workout plans, and more. This is not an offer or promise
          of results. Consult your physician for specific recommendations
          regarding your health conditions.
        </p>
      </div>
    </div>

    <h2>People Often Ask</h2>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 48px;
        width: 100%;
      "
    >
      <BaseQuestion
        title="How is my plan created?"
        description="We use the answers you provided and our unique algorithms to tailor your program specifically to your individual needs and goals. Our programs are verified by experts, ensuring that your program will work for you."
      />
      <BaseQuestion
        title="How do I see my program?"
        description="Each plan is personalized, and our fitness experts work hard to ensure you'll love it. It usually takes a few hours to receive access to your plan via email."
      />
      <BaseQuestion
        title="Are there any device requirements?"
        description="To access and use your plan, you need a smartphone running iOS 14.0 or later, or Android 8.0 or later."
      />
    </div>
    <!-- <h2>Users Love Our Plans</h2> -->
    <!-- <div style="display: flex; justify-content: center">
      <div class="review">
        <div>⭐ ⭐ ⭐ ⭐ ⭐</div>
        <p style="font-size: 16px">
          I'm using your app, and I love it! I've tried several home training
          apps, but none were as easy to use and effective. The exercises are
          diverse and well-organized.
        </p>
        <p class="user">🥰 @andrew12</p>
      </div>
    </div>
    <div style="display: flex; justify-content: center">
      <div class="review">
        <div>⭐ ⭐ ⭐ ⭐ ⭐</div>
        <p style="font-size: 16px">
          I love it! I've been using it for the last 11 days.
        </p>
        <p class="user">🤩 @cela12</p>
      </div>
    </div>
    <div style="display: flex; justify-content: center">
      <div class="review">
        <div>⭐ ⭐ ⭐ ⭐ ⭐</div>
        <p style="font-size: 16px">
          I love your app. Thumbs up for your good work.
        </p>
        <p class="user">😎️ @peter45</p>
      </div>
    </div> -->
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      "
    >
      <h2>Get noticeable results in just 4 weeks!</h2>
      <div style="display: flex; justify-content: center; align-items: center">
        <div class="targetWeight">
          <p class="targetHeading">Target weight</p>
          <strong style="font-size: 20px"
            >⚖️ {{ userData.goal_weight }}
            {{ userData.goal_weight_unit }}</strong
          >
        </div>
        <div>
          <p class="targetHeading">Starting level</p>
          <strong style="font-size: 20px"
            ><img src="../assets/images/signalMid.svg" />
            {{ yoga_level }}</strong
          >
        </div>
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center">
      <p class="offerEnds">
        🕓 This offer ends in {{ formatedRemainingTime }} min
      </p>
    </div>
    <div
      v-for="offer in offerData.offers"
      :key="offer.planCode"
      style="display: flex; justify-content: center; align-items: center"
    >
      <PlanOption
        style="width: 320px"
        :text="offer.planName"
        :price-per-day="offer.pricePerDay"
        :base-price-per-day="offer.basePricePerDay"
        :base-period-fee="offer.basePeriodFee"
        :period-fee="offer.periodFee"
        :is-selected="selectedPlan === offer.planCode"
        :is-popular="offer.mostPopular"
        :is-sale="!isTimedOut"
        @click="selectedPlan = offer.planCode"
      />
    </div>
    <BaseButton
      id="planButton2"
      color="#2FA177"
      style="height: 80px; margin: 32px 0"
      pulsating
      :disabled="!selectedPlan"
      @click="handleButtonClick()"
      >GET MY PLAN</BaseButton
    >
    <div style="margin: 64px 0 32px 0">
      <img
        width="64px"
        height="64px"
        src="../assets/images/shield.svg"
        alt="Visa"
      />
    </div>
    <h2 v-if="!isSale">30 Day Money-Back Guarantee</h2>
    <div style="display: flex; justify-content: center">
      <p class="description" style="max-width: 600px">
        We believe our plan can work for you, delivering visible results in 4
        weeks! If you don't see visible results and can prove you followed our
        plan, we are willing to return your money. Please check our
        <a class="linkText" target="_blank" href="/Refund-Policy"
          >Refund Policy</a
        >
        for all the requirements.
      </p>
    </div>
    <div style="margin: 12px 0">
      <p class="description">MINDSPARKLE LLC,</p>
      <p class="description">801 Brickell Ave FL8 Miami,</p>
      <p class="description">FL 33131</p>
    </div>
    <p class="description">
      <a class="linkText" href="/Terms-of-Service" target="_blank"
        >Terms of Use</a
      >,
      <a class="linkText" href="/Privacy-Policy" target="_blank"
        >Privacy Policy</a
      >
      and
      <a class="linkText" target="_blank" href="/Refund-Policy"
        >Refund Policy</a
      >
    </p>
  </div>
</template>

<style scoped>
.container-content {
  display: flex;
  flex-direction: column;
}

.disclaimer {
  max-width: 500px;
  font-size: 10px;
  text-align: center;
  margin-top: 16px;
  color: #6c757d;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.option {
  width: 180px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: #ccc;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.value {
  font-size: 16px;
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 2px solid #2fa177;
}

.yoga-level-indicator {
  display: flex;
  justify-content: center;
  margin: 6px 0;
}

.yoga-level-block {
  width: 20px;
  height: 6px;
  border-radius: 4px;
  margin: 0 2px;
  background-color: white;
  transition: background-color 0.3s ease;
}

.yoga-level-block.active {
  background-color: #2fa177;
}
.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  margin: 0 12px;
  height: 200px;
  @media screen and (max-width: 768px) {
    width: 30px;
  }
  @media screen and (max-width: 400px) {
    width: 24px;
  }
}
.guarantee {
  display: flex;
  width: 100%;
  font-size: 12px;
  text-align: start;
}

.description {
  font-size: 16px;
  color: #6c757d;
  padding: 0;
  margin: 0;
}

.linkText {
  text-transform: capitalize;
  text-decoration: none;
  color: #2fa177;
}

.review {
  position: relative;
  width: 400px;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 12px 12px 24px 12px;
  margin-bottom: 36px;
}
.user {
  position: absolute;
  bottom: 0;
  right: 12px;
  font-size: 14px;
  color: #6c757d;
}

.image {
  width: 100%;
  max-width: 500px;
}

.iframe-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.container-payments {
  background-image: linear-gradient(
    to bottom,
    rgba(139, 205, 241, 0.047),
    rgba(37, 110, 219, 0.176)
  );
  border-radius: 6px;
  padding: 6px 16px 10px 16px;
  width: auto;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.container-payments h3 {
  margin: 4px 0px;
  font-size: 12px;
  font-weight: 200;
}

.images-payments {
  display: flex;
  width: 100%;
  gap: 2px;
  align-items: center;
  justify-content: center;
}

.iframe-container {
  position: relative;
  max-width: 540px;
  max-height: 960px;
  margin: 32px 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: white;
}

.close-button {
  position: absolute;
  rotate: 45deg;
  top: 20px;
  left: 20px;
  cursor: pointer;
  height: 48px;
  z-index: 1010;
}
.close-button:hover {
  opacity: 0.9;
}
.close-button:active {
  transform: scale(0.85);
}
.extra {
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  max-width: 500px;
  background-color: rgba(139, 241, 180, 0.3);
  @media screen and (max-width: 768px) {
    padding: 8px;
  }
}
.targetWeight {
  padding: 12px;
  margin: 12px;
  border-right: #6c757d 1px solid;
}
.targetHeading {
  font-size: 12px;
}

.offerEnds {
  color: white;
  font-weight: bold;
  background-color: #f05454;
  padding: 16px;
  width: 400px;
  border-radius: 12px;
}

.screen {
  width: 200px;
  height: fit-content;
}

.appinfo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}
.swiper {
  width: 100%;
  height: 100%;
  display: flex;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 200px;
  height: 400px;
  padding-bottom: 28px;
}

.container-points {
  max-width: 480px;
  margin-bottom: 32px;
}

.app-point {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 20px 0;
  gap: 10px;
  font-size: 18px;
  background-color: #dfdffb6b;
  border-radius: 8px;
  padding: 8px 20px 8px 20px;
}

.app-point p {
  font-size: 14px;
}

.container-results {
  margin-bottom: 40px;
}

.heading-results {
  font-size: 26px;
}
.user-result {
  background-image: linear-gradient(to bottom, #94e9ca90, #2fa17784);
  border-radius: 8px;
  max-width: 240px;
  height: 460px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2fr;
  padding-bottom: 26px;
}

.user-result img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.user-result p {
  font-size: 12px;
  padding: 0 26px 12px 26px;
  font-weight: 400;
}

.info-lbs {
  background-color: #fff;
  border-radius: 4px;
  padding: 2px 26px;
}

.topButton {
  display: flex;
  position: fixed;
  right: 0;
  gap: 12px;
  margin-right: 8px;
  align-items: center;
  @media screen and (max-width: 1600px) {
    visibility: hidden;
  }
}
.topButton p,
.centerTopButton p {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
}
.topButton .time,
.centerTopButton .time {
  color: #2fa177;
  font-size: 26px;
}
.centerTopButton {
  visibility: hidden;
  display: flex;
  position: fixed;
  background-color: white;
  width: 100%;
  height: 90px;
  right: 0;
  top: 0;
  z-index: 1000;
  gap: 20px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1600px) {
    visibility: visible;
  }
}

.hidden {
  visibility: hidden;
}

.bgbox {
  width: fit-content;
  border-radius: 12px;
  background-color: rgba(97, 186, 121, 0.2);
  background-image: url("../assets/images/texture-hero-planoffer4.png");
  background-size: cover;
  background-position: center;
}
.label {
  display: flex;
  justify-content: center;
  gap: 34px;
  /*margin: 24px 0;*/
  margin: 18px 40px 32px 40px;
  @media screen and (max-width: 768px) {
    gap: 8px;
  }
  @media screen and (max-width: 400px) {
    margin: 16px 16px 32px 16px;
  }
}
.labelNow {
  width: 100px;
  background-color: white;
  color: #2fa177;
  padding: 16px 32px;
  border-radius: 12px;
  @media screen and (max-width: 768px) {
    padding: 16px 16px;
  }
}

.labelNow h3,
.labelGoal h3 {
  margin: 0;
}

.labelDivider {
  width: 2px;
  background-color: #2fa177;
  margin: 6px 12px;
  border-radius: 8px;
}
.labelGoal {
  width: 100px;
  background-color: #2fa177;
  color: white;
  padding: 16px 32px;
  border-radius: 12px;
  @media screen and (max-width: 768px) {
    padding: 16px 16px;
  }
}
.goalImage {
  width: revert;
  height: 350px;
  @media screen and (max-width: 768px) {
    height: 260px;
    max-width: 100%;
  }
}
</style>
<style>
.no-scroll {
  overflow-y: hidden;
}
</style>
