<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { onMounted, ref } from "vue";
import BaseButton from "../components/BaseButton.vue";
guardNoCookie();
useProgress().set(28);
const progress = ref(0);
const interval = 100;
const step = 1;
function updateProgress() {
  const intervalId = setInterval(() => {
    progress.value += step;
    if (progress.value >= 100) {
      clearInterval(intervalId);
    }
  }, interval);
}
onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
  updateProgress();
  startImageRotation();
});
// const isFemale = computed(() => store.state.gender === "female");
const currentImageIndex = ref(0);

function startImageRotation() {
  setInterval(() => {
    currentImageIndex.value = (currentImageIndex.value + 1) % 5;
  }, 4500);
}
</script>

<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>Creating your personalized plan</h2>
    <div style="display: flex; justify-content: center; align-items: center">
      <div class="progress-circle" data-percentage="0">
        <p class="percentage">{{ progress }}%</p>
      </div>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      "
    >
      <BaseButton
        :disabled="!(progress >= 100)"
        style="margin-top: 32px"
        @click="saveAndNavigate('continue', '', '/Enter-Email')"
      >
        {{ progress >= 100 ? "CONTINUE" : "CALCULATING ..." }}
      </BaseButton>
      <div class="heading">
        <h3>
          <span class="highlight">25+ million</span> people have chosen our
          products
        </h3>
      </div>
      <div class="carousel-container">
        <transition-group name="carousel" tag="div" class="carousel-wrapper">
          <div :key="currentImageIndex" class="carousel-item">
            <div class="carousel-show">
              <div class="carousel-box">
                <div v-show="currentImageIndex === 0">⭐ ⭐ ⭐ ⭐ ⭐</div>
                <p v-show="currentImageIndex === 0" class="carousel-text">
                  "I love this app! The yoga sessions are so relaxing, and the
                  workout programs really challenge me. Perfect combination for
                  mind and body fitness."
                </p>
                <p v-show="currentImageIndex === 0" class="carousel-username">
                  👩🏽‍🦰 Anna
                </p>
              </div>
            </div>
          </div>
          <div :key="currentImageIndex" class="carousel-item">
            <div class="carousel-show">
              <div class="carousel-box">
                <div v-show="currentImageIndex === 1">⭐ ⭐ ⭐ ⭐ ⭐</div>
                <p v-show="currentImageIndex === 1" class="carousel-text">
                  "Excellent fitness app! The variety of workouts keeps things
                  interesting, and the yoga routines help me stay flexible.
                  Highly recommend!"
                </p>
                <p v-show="currentImageIndex === 1" class="carousel-username">
                  👨🏻‍🦱 Mark
                </p>
              </div>
            </div>
          </div>
          <div :key="currentImageIndex" class="carousel-item">
            <div class="carousel-show">
              <div class="carousel-box">
                <div v-show="currentImageIndex === 2">⭐ ⭐ ⭐ ⭐ ⭐</div>
                <p v-show="currentImageIndex === 2" class="carousel-text">
                  "Best fitness app I've tried! The yoga and workout programs
                  are well-structured and easy to follow. I've seen great
                  results in just a few weeks!"
                </p>
                <p v-show="currentImageIndex === 2" class="carousel-username">
                  👩🏻‍🦱 Emily.Sd
                </p>
              </div>
            </div>
          </div>
          <div :key="currentImageIndex" class="carousel-item">
            <div class="carousel-show">
              <div class="carousel-box">
                <div v-show="currentImageIndex === 3">⭐ ⭐ ⭐ ⭐ ⭐</div>
                <p v-show="currentImageIndex === 3" class="carousel-text">
                  "Fantastic app with amazing yoga and workout options. It has
                  everything I need to stay in shape and improve my flexibility.
                  Five stars!"
                </p>
                <p v-show="currentImageIndex === 3" class="carousel-username">
                  🧔🏾 John89
                </p>
              </div>
            </div>
          </div>
          <div :key="currentImageIndex" class="carousel-item">
            <div class="carousel-show">
              <div class="carousel-box">
                <div v-show="currentImageIndex === 4">⭐ ⭐ ⭐ ⭐ ⭐</div>
                <p v-show="currentImageIndex === 4" class="carousel-text">
                  "I'm thrilled with this app! The yoga classes are incredibly
                  soothing, and the workout plans are super effective. It's a
                  perfect blend for a healthy lifestyle."
                </p>
                <p v-show="currentImageIndex === 4" class="carousel-username">
                  🧑🏼‍🦰 Sarah
                </p>
              </div>
            </div>
          </div>
        </transition-group>
        <!-- <transition-group name="carousel" tag="div" class="carousel-wrapper">
          <div :key="currentImageIndex" class="carousel-item">
            <img
              v-show="currentImageIndex === 0"
              :src="
                isFemale
                  ? require('../assets/images/sideLoadingW.png')
                  : require('../assets/images/sideLoading1.png')
              "
              alt="Activity"
              class="side-image"
            />
            <img
              v-show="currentImageIndex === 1"
              :src="
                isFemale
                  ? require('../assets/images/sideLoadingW1.png')
                  : require('../assets/images/sideLoading.png')
              "
              alt="Activity"
              class="side-image"
            />
            <img
              v-show="currentImageIndex === 2"
              :src="
                isFemale
                  ? require('../assets/images/sideLoadingW2.png')
                  : require('../assets/images/sideLoading2.png')
              "
              alt="Activity"
              class="side-image"
            />
          </div>
        </transition-group> -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.progress-circle {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: conic-gradient(#4263eb calc(v-bind(progress) * 1%), #ddd 0);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 100ms ease-out;
}
.percentage {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading {
  margin-top: 40px;
}
.heading h3 {
  font-weight: bold;
  @media screen and (max-width: 580px) {
    font-weight: normal;
    font-size: 18px;
  }
}
.heading .highlight {
  font-weight: bold;
  font-size: 20px;
}
@media screen and (max-width: 580px) {
  .heading .highlight::after {
    content: "\A";
    white-space: pre;
  }
}

.carousel-container {
  overflow: hidden;
  width: 600px;
  height: 200px;
  position: relative;
  border: 1px solid #4263eb;
  border-radius: 10px;
  @media screen and (max-width: 680px) {
    width: 100%;
  }
  @media screen and (max-width: 580px) {
    height: 250px;
  }
  @media screen and (max-width: 480px) {
    height: 270px;
  }
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-item {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.carousel-box {
  padding: 24px 60px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  @media screen and (max-width: 580px) {
    gap: 20px;
    padding: 22px 50px;
  }
  @media screen and (max-width: 480px) {
    gap: 22px;
    padding: 14px 50px;
  }
}
.carousel-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}
.carousel-username {
  font-size: 14px;
}
.carousel-text,
.carousel-username {
  margin: 0;
}
/*
.side-image {
  width: 400px;
}

.side-image {
  width: 100%;
  height: auto;
}
  */

.carousel-enter-active,
.carousel-leave-active {
  transition: transform 1s;
}

.carousel-enter-from {
  transform: translateX(100%);
}
.carousel-leave-to {
  transform: translateX(-100%);
}

.carousel-enter-to,
.carousel-leave-from {
  transform: translateX(0);
}
</style>
