<script setup>
import axios from 'axios';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BaseButton from '../components/BaseButton.vue';
import BaseTextInput from '../components/BaseTextInput.vue';
import { useProgress } from '../logic/useProgress';
const router = useRouter();

useProgress().set(0);
const password = ref();
const isValidPassword = ref(false);
const confirmPassword = ref();
const isValidConfirmPassword = ref(false);

const passwordError = computed(() => {
    return password.value && confirmPassword.value && password.value !== confirmPassword.value;
});

const route = useRoute();
const authToken = ref(route.query.at);


async function resetPassword(){
    try {
    await axios.post('/api/VarStore/SetPassword', {
      password: password.value,
      authToken: authToken.value
    });
    router.push('/Login');
  } catch (error) {
    console.error('Reset Password error:', error);
  }
}
</script>
<template>
    <img src="../../public/logo.png" alt="logo" width="120px" class="logo"/>
    <h1>Set New Password</h1>
    <div style="display: flex; justify-content: center;">
    <p class="field">New Password</p>
    </div>
          <BaseTextInput
    v-model="password"
    :width="600"
    is-password
    placeholder="Enter your password"
    error-message="Password must be at least 3 characters long."
    @update:is-valid="isValidPassword = $event"
    />
        <div style="display: flex; justify-content: center;">
    <p class="field">Confirm Password</p>
    </div>
    <BaseTextInput
    v-model="confirmPassword"
    :width="600"
    is-password
    placeholder="Confirm password"
    error-message="Password must be at least 3 characters long."
    @update:is-valid="isValidConfirmPassword = $event"
    />
    <p v-if="passwordError" class="error">Passwords dont match.</p>
    <BaseButton
        :disabled="!isValidPassword || !isValidConfirmPassword || passwordError"
        @click="resetPassword">
        RESET PASSWORD
    </BaseButton>
</template>

<style scoped>
.field {
    font-size: 16px;
    font-weight: lighter;
    color: #495057;
    margin: 0;
    width: 100%;
    max-width: 600px;
    text-align: left;
}
.error {
    color: red;
    font-size: 16px;
    margin-top: 0;
}

.logo {
  margin: 16px;
}
</style>