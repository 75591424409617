<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>How many push-ups can you do?</h2>
              <div class="options">
      <BaseOption
        text="Less than 10"
        :img-src="require('../assets/images/ok.svg')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('push_ups_do','10')"
        :img-size="40"
        @click="saveAndNavigate('push_ups_do', '10', sNavigate)"
      />
      <BaseOption
        text="10-20"
        :img-src="require('../assets/images/like.svg')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('push_ups_do','30')"
        :img-size="40"
        @click="saveAndNavigate('push_ups_do', '30', sNavigate)"
      />
      <BaseOption
        text="More than 20"
        :img-src="require('../assets/images/biceps.svg')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('push_ups_do','20+')"
        :img-size="40"
        @click="saveAndNavigate('push_ups_do', '20+', sNavigate)"
      />
      <BaseOption
        text="Never tried it"
        :img-src="require('../assets/images/thinking.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('push_ups_do','n/a')"
        @click="saveAndNavigate('push_ups_do', 'n/a', sNavigate)"
      />
    </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { ref, onMounted } from "vue";
import BaseOption from "../components/BaseOption.vue";
import { useSavedData } from "../store/savedDataStore";
  const appVer = ref(process.env.VUE_APP_VER);

  let sNavigate = '';
  if (appVer.value === "YOGA") {
    sNavigate = '/Yoga-Level';
  } else if (appVer.value === "CALI") {
    sNavigate = '/Calisthenics-Level';
  }
  // console.log('APP: ' + appVer.value);
  // console.log('NAV: '+ sNavigate);

  guardNoCookie();
useProgress().set(11);
const { isSelected } = useSavedData();

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
</script>
<style scoped>
.options {
  margin: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>