<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <div style="display: flex; justify-content: center; width: 100%">
      <h2 style="font-size: 30px; max-width: 560px; line-height: 48px">
        Do you want to receive emails with {{appSport}} and Fitness tips,
        <span class="largeText">special offers</span>
        and our product updates?
      </h2>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
      "
    >
      <div class="sticky-button">
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
          "
        >
          <BaseButton
            @click="
              saveAndNavigate('email_updates', 'yes', '/Plan-Illustration')
            "
          >
            SURE, I'M IN!
          </BaseButton>
          <BaseButton
            secondary
            :width="300"
            @click="
              saveAndNavigate('email_updates', 'no', '/Plan-Illustration')
            "
          >
            NO, THANKS
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { onMounted, ref } from "vue";
import BaseButton from "../components/BaseButton.vue";

const appSport = ref(process.env.VUE_APP_SPORT);

guardNoCookie();
useProgress().set(30);

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
</script>
<style scoped>
.largeText {
  color: #4263eb;
}
</style>
