<script setup>
import { useProgress } from "../logic/useProgress";
import { ref } from "vue";

  const appDomain = ref(process.env.VUE_APP_DOMAIN_CAP);
  const appURL = ref(process.env.VUE_APP_URL);
  const appName = ref(process.env.VUE_APP_NAME);
  useProgress().set(0);
</script>

<template>
  <img src="../../public/logo.png" alt="logo" width="120px" class="logo" />
  <h1>{{appDomain}} REFUND POLICY</h1>
  <div style="text-align: left">
    <p style="font-style: italic">Last updated March 26, 2024</p>

    <p>
      THIS REFUND POLICY APPLIES ONLY IF YOUR ACCESS TO {{appName}} (THE
      "SERVICE") HAS BEEN PURCHASED DIRECTLY ON THE WEBSITE AVAILABLE VIA THE
      LINK
      <a :href="`https://${appURL}`" style="color: #4263eb" target="_blank">https://{{appURL}}</a>
      (THE "WEBSITE"). ANY FEATURES OF THE SERVICE (SUBSCRIPTIONS, ADD-ON ITEMS)
      PURCHASED VIA AN APP STORE ARE SUBJECT TO SUCH APP STORE'S REFUND
      POLICIES. THUS, YOU HAVE TO CONTACT AN APP STORE'S SUPPORT TO REQUEST A
      REFUND.
    </p>
    <p>
      By using the Service on any computer, mobile phone, tablet or other device
      (collectively the "Device"), you as a user of the Service confirm that you
      have read, understand and agree to be bound by this Refund Policy and any
      other applicable law.
    </p>

    <p>
      We may change this Refund Policy at any time without notice, effective
      upon its posting on the Website. Your continued use of the Service shall
      be considered your acceptance of the revised Refund Policy. If you do not
      agree to this Refund Policy, please do not use this Service.
    </p>
    <h4>
      Through the Website you may purchase the access to the paid Content (as
      defined in the
      <a class="linkText" href="/Terms-of-Service" target="_blank"
        >Terms of Use</a
      >) of the Service ("Purchase") using any of the following payment methods:
    </h4>
    <p>
      ⇨ Bank Card: you can make a Purchase simply and securely using your
      Mastercard or Visa. Your bank card information will be encrypted at the
      point of transaction.
    </p>

    <p>
      ⇨ PayPal: you can make a Purchase simply and securely via our Website,
      opting for the PayPal payment method during checkout.
    </p>
    <p>
      ⇨ Apple Pay: you can make a Purchase simply and securely via our Website,
      opting for the Apple Pay payment method during checkout.
    </p>
    <p>
      ⇨ Google Pay: you can make a Purchase simply and securely via our Website,
      opting for the Google Pay payment method during checkout.
    </p>

    <p>⇨ The cost of the Purchase is provided within the Service.</p>

    <p>
      You will be granted access to the Content immediately after your
      transaction is successfully validated by our servers and upon setting up a
      password.
    </p>

    <p>
      Please note that to process the payment we use third-party services (i.e.
      payment processors). Such services enable the transaction initiated by you
      and notify us of its completion. We do not store or collect your payment
      details ourselves. This information is provided directly to our
      third-party payment processors.
    </p>

    <p>
      You agree that the Purchase is final, that we will not refund any
      transaction once it has been made and that the Purchase cannot be
      cancelled. Notwithstanding the foregoing, you may be eligible to receive a
      refund in the following cases:
    </p>
    <p>
      (A) You have experienced technical issues with your Purchase and, as a
      result, your use of the Purchase is rendered impossible.
    </p>
    <h4>
      To understand whether you are eligible for a refund, we may ask you to
      provide the following information:
    </h4>
    <p>⇨ your device's model, operating system and version;</p>

    <p>⇨ the steps you are taking when this issue appears;</p>

    <p>⇨ a screen video or screenshots that would illustrate the issue;</p>

    <p>
      ⇨ any additional information that would help us identify the problem, if
      the details you have already provided are not enough to identify the issue
      you're experiencing.
    </p>

    <p>
      The technical issues may include material crashes, failure to launch,
      non-clickability of buttons and other issues, which render your use of the
      Purchase impossible.
    </p>

    <p>
      (B) If you did not get visible results after following the Content
      recommendations and met all of the following conditions:
    </p>
    <p>
      ⇨ you contact us within 30 (thirty) calendar days as of the Purchase date;
      and
    </p>

    <p>
      ⇨ you have followed our personalized workout plan at least during 14
      (fourteen) consecutive days; and
    </p>

    <p>
      ⇨ you are able to demonstrate that you have actually followed the plan by
      providing us with a screen video or screenshots of workout plan history,
      which you have personally passed.
    </p>
    <p>
      To be eligible for a refund on the grounds of not getting visible results,
      the minimum number of completed workouts in your plan during 14 (fourteen)
      consecutive days (two weeks) should be equal to at least 1 (one) workout
      session each day.
    </p>

    <p>
      All refunds will be calculated according to the actual Purchase price paid
      at the time of Purchase. Please note that returns for credit and debit
      card transactions are issued within 2 (two) business days, however it may
      take up to 5 (five) business days for the credit to arrive at your credit
      card or bank account.
    </p>

    <p>
      If you are mistakenly sent a different product than what you ordered on
      the Website, we will replace the product at no additional cost to you.
    </p>

    <p>
      We are not liable for any refund amount due to technical problems on your
      computer, including but not limited to: printer malfunction, inability to
      install Adobe Acrobat Reader, iZip, WinZip, and/or problems due to
      Internet connectivity.
    </p>

    <p>
      If you want to cancel your Purchase and are entitled to any refund, we
      reserve the right to charge a fee to cover the cost of any administrative
      or other expenses we may have suffered due to your order, to the extent
      permitted by law.
    </p>

    <p>
      To request a refund or exchange, please contact Website customer service
      at  <img src="../assets/images/ei.png" alt="support" class="image" />
    </p>

    <p>
      If you live in the European Union you have certain rights to withdraw from
      distance purchases; however, please note that when you order the Purchase,
      you acknowledge and agree that you accept and consume the Purchase
      promptly once our servers validate your Purchase and the purchased Content
      is successfully delivered to you and therefore your right of withdrawal is
      lost at this point. BY STARTING DOWNLOADING THE PURCHASE YOU HEREBY
      EXPRESSLY CONSENT TO IMMEDIATE PERFORMANCE OF THE AGREEMENT AND
      ACKNOWLEDGE THAT YOU WILL LOSE YOUR RIGHT OF WITHDRAWAL FROM THE AGREEMENT
      ONCE OUR SERVERS VALIDATE YOUR PURCHASE AND THE APPLICABLE PURCHASE IS
      SUCCESSFULLY DELIVERED TO YOU.
    </p>

    <p>
      If we incur costs and/or expenses because a payment is declined and this
      is your fault (e.g. because there are insufficient funds in the account or
      the credit card limit has already been exhausted), then we are entitled to
      bill you for the actual costs and/or expenses incurred.
    </p>

    <p>
      Where there is a legitimate reason, we reserve the right for each Purchase
      to refrain from offering certain payment methods and to specify
      alternative payment methods.
    </p>
  </div>
</template>

<style scoped>
p {
  font-size: 16px;
}

.linkText {
  text-transform: capitalize;
  text-decoration: none;
  color: #4263eb;
}
  .image {
    vertical-align: top; /* Zarovnání obrázku nahoře */
    margin-top: 1px;
  }
</style>
