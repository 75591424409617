<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>How much time do you spend walking on a typical day?</h2>
          <div class="options">
      <BaseOption
        text="Less than 20 mins"
        :img-src="require('../assets/images/pitch.svg')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('time_walking','20')"
        :img-size="40"
        @click="saveAndNavigate('time_walking', '20', '/Push-Ups-Do')"
      />
      <BaseOption
        text="20-60 mins"
        :img-src="require('../assets/images/ok.svg')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('time_walking','60')"
        :img-size="40"
      @click="saveAndNavigate('time_walking', '60', '/Push-Ups-Do')"
      />
      <BaseOption
        text="More than 60 mins"
        :img-src="require('../assets/images/like.svg')"
        img-alt="Man"
        is-single
        :is-selected="isSelected('time_walking','60+')"
        :img-size="40"
        @click="saveAndNavigate('time_walking', '60+', '/Push-Ups-Do')"
      />
    </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { onMounted } from "vue";
import BaseOption from "../components/BaseOption.vue";
import { useSavedData } from "../store/savedDataStore";
guardNoCookie();
useProgress().set(10);
const { isSelected } = useSavedData();

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
</script>
<style scoped>
.options {
  margin: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>