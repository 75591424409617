const { ref } = require("vue");

const data = ref({});

export function useSavedData() {
    function saveData(name, value){
        data.value[name] = value;
    }
    function isSelected(varname, value) {
  if(!data.value[varname]) return false;
  return data.value[varname] === value;
}

  return { data, saveData, isSelected };
}