import store from "@/store";

export function useProgress() {
  const set = (step) => {
    store.commit("setStep", step);
  };
  return {
    set,
    currentStep: store.state.step,
    totalSteps: store.state.totalSteps,
  };
}
