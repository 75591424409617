<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>Select your age.</h2>
    <div class="options">
      <BaseTile
        text="25-34"
        :img-src="
          isFemale
            ? require('../assets/images/woman.png')
            : require('../assets/images/youngMan.png')
        "
        img-alt="Man 25-34"
        :is-selected="isSelected('age_group', '25-34')"
        @click="saveAndNavigate('age_group', '25-34', '/Select-Goals')"
      />
      <BaseTile
        text="35-44"
        :img-src="
          isFemale
            ? require('../assets/images/25W.png')
            : require('../assets/images/man.png')
        "
        img-alt="Man 35-44"
        :is-selected="isSelected('age_group', '35-44')"
        @click="saveAndNavigate('age_group', '35-44', '/Select-Goals')"
      />
      <BaseTile
        text="45-54"
        :img-src="
          isFemale
            ? require('../assets/images/40W.png')
            : require('../assets/images/man40.png')
        "
        img-alt="Man 45-54"
        :is-selected="isSelected('age_group', '45-54')"
        @click="saveAndNavigate('age_group', '45-54', '/Select-Goals')"
      />
      <BaseTile
        text="55-64"
        :img-src="
          isFemale
            ? require('../assets/images/45W.png')
            : require('../assets/images/midMan.png')
        "
        img-alt="Man 55-64"
        :is-selected="isSelected('age_group', '55-64')"
        @click="saveAndNavigate('age_group', '55-64', '/Select-Goals')"
      />
      <BaseTile
        text="65+"
        :img-src="
          isFemale
            ? require('../assets/images/60W.png')
            : require('../assets/images/agedMan.png')
        "
        img-alt="Man 65+"
        :is-selected="isSelected('age_group', '65+')"
        @click="saveAndNavigate('age_group', '65+', '/Select-Goals')"
      />
    </div>
  </div>
</template>

<script setup>
import BaseTile from "@/components/BaseTile.vue";
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { saveOnly } from "@/logic/saveOnly";
import { setCookieAndUID } from "@/logic/setCookieAndUID";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import axios from "axios";
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useSavedData } from "../store/savedDataStore";

setCookieAndUID();
guardNoCookie();
useProgress().set(1);
const route = useRoute();

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);

  const lpv = route.query.lpv;
  if (lpv) {
    store.commit("setLpv", lpv);
  }

  const cr = route.query.cr;
  if (cr) {
    store.commit("setCR", cr);
  }

  const hr = document.referrer;
  if (hr) {
    store.commit("setHR", hr);
  }

  const gender = route.query.g;
  if (gender) {
    store.commit("setGender", gender);
  } else {
    store.commit("setGender", "male");
  }

  const utm_source = route.query.utm_source;
  if (utm_source) {
    store.commit("setSource", utm_source);
  } else {
    const utm_source = route.query.s;
    if (utm_source) {
      store.commit("setSource", utm_source);
    }
  }

  const utm_medium = route.query.utm_medium;
  if (utm_medium) {
    store.commit("setMedium", utm_medium);
  } else {
    const utm_medium = route.query.m;
    if (utm_medium) {
      store.commit("setMedium", utm_medium);
    }
  }

  const utm_campaign = route.query.utm_campaign;
  if (utm_campaign) {
    store.commit("setCampaign", utm_campaign);
  } else {
    const utm_campaign = route.query.c;
    if (utm_campaign) {
      store.commit("setCampaign", utm_campaign);
    }
  }

  const utm_content = route.query.utm_content;
  if (utm_content) {
    store.commit("setContent", utm_content);
  } else {
    const utm_content = route.query.n;
    if (utm_content) {
      store.commit("setContent", utm_content);
    }
  }

  saveOnly("gender", store.state.gender, 0,'/Select-Your-Age-Start');

  if (store.state.country === "") {
    axios
      .get(`/api/VarStore/Session?sid=${store.state.uid}`)
      .then((response) => {
        if (response.data.ipcountry !== null)
          store.state.country = response.data.ipcountry;
        console.log(`ipcountry=${store.state.country}`);
      })
      .catch((error) => {
        console.error("Došlo k chybě při načítání dat:", error);
      });
  }
});
const { isSelected } = useSavedData();

const isFemale = computed(() => store.state.gender === "female");
</script>

<style scoped>
.options {
  display: flex;
  justify-content: center;
  margin-top: 36px;
  flex-wrap: wrap;
  gap: 20px;

  @media screen and (max-width: 768px) {
    gap: 12px;
    margin-top: 24px;
  }
}
</style>
