<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { computed, onMounted, ref } from "vue";
import BaseButton from "../components/BaseButton.vue";
import BaseEmailBox from "../components/BaseEmailBox.vue";
import { useSavedData } from "../store/savedDataStore";
guardNoCookie();
useProgress().set(29);

const emailInput = ref();
const isValidEmail = ref(false);

const dataToSend = computed(() => {
  return `${emailInput.value}`;
});
//const isFemale = computed(() => store.state.gender === "female");
onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
  if (useSavedData().data.value.your_email) {
    emailInput.value = useSavedData().data.value.your_email;
  }
});
</script>

<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <div style="display: flex; justify-content: center; width: 100%">
      <div>
        <h2 style="font-size: 30px; max-width: 600px; line-height: 48px">
          Enter your email to get <br />
          your
          <span class="largeText"> Personalized Workout plan.</span>
        </h2>
        <div class="options">
          <!--p style="margin-bottom: 0">Your email:</p-->
          <BaseEmailBox
            v-model="emailInput"
            style="margin-bottom: -12px"
            placeholder="Enter your email address"
            error-message="Kindly provide a valid email address."
            @update:is-valid="isValidEmail = $event"
          />
          <div class="chosenUs-box">
            <h5>⭐️ 25+ MILLION USERS HAVE CHOSEN OUR PRODUCTS</h5>
          </div>
          <div style="display: flex; justify-content: center">
            <p class="smallText">
              We value your privacy. By using your email, we can make your
              advertising experience more personalized and relevant.
              <a class="linkText" href="/Privacy-Policy" target="_blank"
                >Privacy Policy.</a
              >
            </p>
          </div>
        </div>
        <div class="container-btns">
          <BaseButton
            :disabled="!isValidEmail"
            @click="
              saveAndNavigate('your_email', dataToSend, '/Email-With-Tips')
            "
          >
            CONTINUE
          </BaseButton>
          <BaseButton
            secondary
            @click="saveAndNavigate('your_email', '', '/Plan-Illustration')"
          >
            SKIP
          </BaseButton>
        </div>
      </div>
      <!--img
  style="margin-top: 48px"
    :src="
      isFemale
        ? require('../assets/images/sideEmailW.png')
        : require('../assets/images/sideEmail.png')
    "
    alt="Activity"
    class="side-image"
  /-->
    </div>
  </div>
</template>

<style scoped>
.options {
  margin-bottom: 16px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side-image {
  width: 300px;
  position: absolute;
  right: 0;
  @media (max-width: 1200px) {
    display: none;
  }
}
.chosenUs-box {
  background-image: linear-gradient(to bottom, #74ebd589, #acb6e5);
  padding: 14px 32px;
  border-radius: 9px;

  margin: 34px 0px 20px 0px;
}
.chosenUs-box h5 {
  margin: 0;
  font-size: 14px;
  text-align: center;
}

.container-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.linkText {
  text-transform: capitalize;
  text-decoration: none;
  color: #4263eb;
}
.smallText {
  max-width: 500px;
  font-size: 12px;
  color: #9b9b9b;
}
.largeText {
  color: #4263eb;
}
</style>
