<template>
  <div id="step" class="survey-step">
    <h2>Choose your target zones</h2>
    <div style="display: flex; justify-content: center">
      <div class="image-container">
        <img
          :src="
            isFemale
              ? require('../assets/images/targetW.png')
              : require('../assets/images/target.png')
          "
          class="image"
        />
        <div
          class="dot"
          :class="{ selected: isAnswerSelected('Arms') }"
          :style="{ left: isFemale ? '32px' : '24px' }"
          @click="selectAnswer('Arms')"
        />
        <div
          class="line"
          :class="{ 'line-selected': isAnswerSelected('Arms') }"
        />
        <div
          class="chat-bubble"
          :class="{ selected: isAnswerSelected('Arms') }"
          @click="selectAnswer('Arms')"
        >
          Arms
        </div>
        <div
          class="dot-pecs"
          :class="{ selected: isAnswerSelected('Pecs') }"
          @click="selectAnswer('Pecs')"
        />
        <div
          class="line-pecs"
          :class="{ 'line-selected': isAnswerSelected('Pecs') }"
        />
        <div
          class="chat-bubble-pecs"
          :class="{ selected: isAnswerSelected('Pecs') }"
          @click="selectAnswer('Pecs')"
        >
          Chest
        </div>
        <div
          class="dot-stomach"
          :class="{ selected: isAnswerSelected('Stomach') }"
          @click="selectAnswer('Stomach')"
        />
        <div
          class="line-stomach"
          :class="{ 'line-selected': isAnswerSelected('Stomach') }"
        />
        <div
          class="chat-bubble-stomach"
          :class="{ selected: isAnswerSelected('Stomach') }"
          @click="selectAnswer('Stomach')"
        >
          Stomach
        </div>
        <div
          class="dot-legs"
          :class="{ selected: isAnswerSelected('Legs') }"
          @click="selectAnswer('Legs')"
        />
        <div
          class="line-legs"
          :class="{ 'line-selected': isAnswerSelected('Legs') }"
        />
        <div
          class="chat-bubble-legs"
          :class="{ selected: isAnswerSelected('Legs') }"
          @click="selectAnswer('Legs')"
        >
          Legs
        </div>
      </div>
      <div>
        <div class="sticky-content">
          <!-- <div
            v-for="(answer, index) in ANSWERS"
            :key="index"
            class="selection"
          >
            <BasePill
              v-if="!isMobile"
              :id="answer.value"
              style="width: 120px"
              :text="answer.text"
              :is-selected="answer.selected"
              @click="answer.selected = !answer.selected"
            />

            <BaseOption
              v-else
              :id="answer.value"
              :text="answer.text"
              :img-src="answer.img"
              :img-alt="answer.text"
              :is-selected="answer.selected"
              @click="answer.selected = !answer.selected"
            />
          </div> -->
        </div>
        <div class="sticky-button">
          <BaseButton
            :disabled="!isAnyAnswerSelected"
            @click="
              saveAndNavigate('target_zones', dataToSend, '/Happy-With-Body')
            "
            >CONTINUE</BaseButton
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { computed, onMounted, reactive } from "vue";
import BaseButton from "../components/BaseButton.vue";
import { useSavedData } from "../store/savedDataStore";
guardNoCookie();
useProgress().set(7);
const isFemale = computed(() => store.state.gender === "female");
const ANSWERS = reactive([
  {
    text: "Arms",
    value: "Arms",
    img: isFemale.value
      ? require("../assets/images/armsW.png")
      : require("../assets/images/arms.png"),
    selected: false,
  },
  {
    text: "Pecs",
    value: "Pecs",
    img: isFemale.value
      ? require("../assets/images/cutW.png")
      : require("../assets/images/pecs.png"),
    selected: false,
  },
  {
    text: "Stomach",
    value: "Stomach",
    img: isFemale.value
      ? require("../assets/images/stomachW.png")
      : require("../assets/images/stomach.png"),
    selected: false,
  },
  {
    text: "Legs",
    value: "Legs",
    img: isFemale.value
      ? require("../assets/images/legsW.png")
      : require("../assets/images/legs.png"),
    selected: false,
  },
]);
function selectAnswer(answer) {
  ANSWERS.forEach((a) => {
    if (a.value === answer) {
      a.selected = !a.selected;
    }
  });
}
function isAnswerSelected(value) {
  console.log(value);
  return ANSWERS.some((answer) => answer.value === value && answer.selected);
}
const isAnyAnswerSelected = computed(() => {
  return ANSWERS.some((answer) => answer.selected);
});

const dataToSend = computed(() =>
  ANSWERS.filter((answer) => answer.selected)
    .map((answer) => answer.value)
    .join(",")
);

function isSelected(value) {
  const zones = useSavedData().data.value.target_zones;
  if (!zones) return false;
  const zonesArray = zones.split(",");
  return zonesArray.includes(value);
}

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
  ANSWERS.forEach((answer) => {
    answer.selected = isSelected(answer.value);
  });
});
</script>
<style scoped>
.image {
  width: 173px;
  object-fit: contain;
  height: 400px;
}

.selection {
  display: flex;
  justify-content: center;
}
.image-container {
  margin-top: 32px;
  position: relative;
  width: fit-content;
  max-width: 400px;
  height: max-content;
}

.dot {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 50%;
  top: 118px;
  cursor: pointer;
  z-index: 2;
}
.line {
  position: absolute;
  width: 54px;
  height: 108px;
  border-left: #ccc 2px solid;
  border-bottom: #ccc 2px solid;
  border-bottom-left-radius: 12px;
  top: 20px;
  left: -20px;
}

.chat-bubble {
  position: absolute;
  top: 20px;
  left: -20px;
  min-width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  transform: translate(-50%, -50%);
  user-select: none;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    min-width: 80px;
  }
}
.selected {
  background-color: #4263eb !important;
  color: white;
  border-color: #4263eb !important;
}
.line-selected {
  border-color: #4263eb !important;
}
.dot-pecs {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 50%;
  top: 90px;
  right: 54px;
  cursor: pointer;
  z-index: 2;
}
.line-pecs {
  position: absolute;
  width: 74px;
  height: 80px;
  border-right: #ccc 2px solid;
  border-bottom: #ccc 2px solid;
  border-bottom-right-radius: 12px;
  top: 20px;
  right: -20px;
}

.chat-bubble-pecs {
  position: absolute;
  top: 20px;
  right: -140px;
  min-width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  transform: translate(-50%, -50%);
  user-select: none;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    min-width: 80px;
    right: -120px;
  }
}
.dot-stomach {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 50%;
  bottom: 240px;
  left: 80px;
  cursor: pointer;
  z-index: 2;
}
.line-stomach {
  position: absolute;
  width: 130px;
  height: 150px;
  border-top: #ccc 2px solid;
  border-left: #ccc 2px solid;
  border-top-left-radius: 12px;
  bottom: 100px;
  left: -40px;
}

.chat-bubble-stomach {
  position: absolute;
  bottom: 64px;
  left: -40px;
  min-width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  transform: translate(-50%, -50%);
  user-select: none;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    min-width: 80px;
  }
}

.dot-legs {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 50%;
  bottom: 160px;
  right: 50px;
  cursor: pointer;
}
.line-legs {
  position: absolute;
  width: 90px;
  height: 68px;
  border-top: #ccc 2px solid;
  border-right: #ccc 2px solid;
  border-top-right-radius: 12px;
  bottom: 100px;
  right: -40px;
}

.chat-bubble-legs {
  position: absolute;
  bottom: 64px;
  right: -160px;
  min-width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  transform: translate(-50%, -50%);
  user-select: none;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    min-width: 80px;
    right: -140px;
  }
}
</style>
