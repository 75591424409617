<template>
  <div class="switch-container">
    <div class="value-container">
      <span :class="{ active: model === firstValue }" @click="model = firstValue">{{ firstText }}</span>
      <span :class="{ active: model === secondValue }" @click="model = secondValue">{{ secondText }}</span>
    </div>
    <div class="indicator" :style="{ transform: model === secondValue ? 'translateX(100%)' : 'translateX(0)' }"/>
  </div>
</template>

<script setup>
defineProps({
  firstText: String,
  secondText: String,
  firstValue: String,
  secondValue: String,
});
const model = defineModel()

</script>

<style scoped>
.switch-container {
  position: relative;
  width: 160px;
  height: 48px;
  border: 1px solid #ced4da;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8); 
  user-select: none;
  overflow: hidden;
}

.value-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.value-container span {
  transition: opacity 0.5s ease;
  opacity: 0.7;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  color: #000;
}

.value-container span.active {
  opacity: 1;
  color: white;
}

.indicator {
  position: absolute;
  width: 50%;
  height: 100%;
  border-radius: 24px;
  background-color: #4263eb;
  transition: transform 0.2s ease;
  z-index: 0;
}
</style>
