<script setup>
import axios from "axios";
import { ref } from "vue";
import { useRouter } from "vue-router";
import BaseButton from "../components/BaseButton.vue";
import BaseEmailBox from "../components/BaseEmailBox.vue";
import BaseTextInput from "../components/BaseTextInput.vue";
import { useProgress } from "../logic/useProgress";
import { saveToken } from "../utils/useAuth";

const router = useRouter();
const emailInput = ref();
const password = ref();
const isValidEmail = ref(false);
const isValidPassword = ref(false);

const loginError = ref("");
useProgress().set(0);

async function login() {
  try {
    await axios
      .post("/api/VarStore/Login", {
        email: emailInput.value,
        password: password.value,
      })
      .then((response) => {
        saveToken(response.data.auth);
        router.push("/Billing-Page");
      });
  } catch (error) {
    console.error("Login error:", error);
    loginError.value = "Invalid email or password";
  }
}
</script>

<template>
  <div class="wrap">
    <div class="left">
      <div class="background" />
      <img src="../assets/images/users.png" alt="Our Users" class="image" />
    </div>
    <div class="content">
      <img src="../../public/logo.png" alt="logo" width="80px" class="logo" />
      <h1>Log In</h1>
      <BaseEmailBox
        v-model="emailInput"
        :width="600"
        placeholder="Enter your email address."
        error-message="Kindly provide a valid email address."
        @update:is-valid="isValidEmail = $event"
      />
      <BaseTextInput
        v-model="password"
        style="margin-bottom: 0"
        :width="600"
        is-password
        placeholder="Enter your password"
        error-message="Password must be at least 3 characters long."
        @update:is-valid="isValidPassword = $event"
      />
      <div style="display: flex; width: 100%; justify-content: end">
        <RouterLink to="/Reset-Password" class="linkText"
          >Forgot your password?</RouterLink
        >
      </div>
      <p v-if="loginError" class="error">{{ loginError }}</p>
      <BaseButton
        :disabled="!isValidEmail || !isValidPassword"
        style="margin-top: 36px"
        @click="login"
        >LOG IN</BaseButton
      >
      <p class="subText">
        Don't have an account?
        <RouterLink to="/Select-Your-Gender" class="linkText"
          >Start the quiz</RouterLink
        >
      </p>
    </div>
  </div>
</template>

<style scoped>
.wrap {
  display: flex;
  gap: 64px;
  margin-top: 64px;
}
.image {
  width: 100%;
  max-width: 700px;
  z-index: 2;
}

.background {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(66, 99, 235, 0.15);
  z-index: 0;
}
.content {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.left {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;

  @media screen and (max-width: 768px) {
    display: none;
  }
}
.logo {
  position: absolute;
  top: 0;
  left: 0;
  margin: 16px;
  z-index: 3;
  @media screen and (max-width: 768px) {
    position: relative;
    margin: 0;
  }
}
.linkText {
  text-transform: capitalize;
  text-decoration: none;
  color: #4263eb;
  font-size: 16px;
}
.subText {
  margin-top: 20px;
  font-size: 16px;
}

.error {
  color: red;
  font-size: 18px;
  margin-bottom: 0;
}
</style>
