<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>How much water do you drink every day?</h2>
    <div class="options">
      <BaseOption
        text="None. I drink coffee and tea"
        :img-src="require('../assets/images/tea.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('water_drink','0')"
        @click="saveAndNavigate('water_drink', '0', '/Follow-Diets')"
      />
      <BaseOption
        text="About 2 glasses"
        :img-src="require('../assets/images/peace.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('water_drink','2')"
        @click="saveAndNavigate('water_drink', '2', '/Follow-Diets')"
      />
      <BaseOption
        text="Between 3 and 6 glasses"
        :img-src="require('../assets/images/ok.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('water_drink','3-6')"
        @click="saveAndNavigate('water_drink', '3-6', '/Follow-Diets')"
      />
      <BaseOption
        text="A lot - probably more than 6 glasses"
        :img-src="require('../assets/images/hands.svg')"
        img-alt="Man"
        is-single
        :img-size="40"
        :is-selected="isSelected('water_drink','7+')"
        @click="saveAndNavigate('water_drink', '7+', '/Follow-Diets')"
      />
      </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { onMounted } from "vue";
import BaseOption from "../components/BaseOption.vue";
import { useSavedData } from "../store/savedDataStore";
guardNoCookie();
useProgress().set(17);

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
});
const { isSelected } = useSavedData();

</script>
<style scoped>
.options {
  margin: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>