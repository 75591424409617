<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <div style="display: flex; justify-content: center; width: 100%">
      <div class="options">
        <BaseInfo
          style="margin-bottom: 32px"
          :title="`REALISTIC TARGET: lose ${perc_reduce}% of your weight`"
          :text="`Lower your risk of chronic health problems, such as heart disease and type 2 diabetes. According to CDC recommendations, losing ${
            isKg ? '4 kg' : '8.8 lbs'
          } per month is healthy and safe.`"
        />
        <h2>What is your age?</h2>
        <BaseNumberBox
          ref="numberInputEl"
          v-model="numberInput"
          :min="16"
          :max="99"
          placeholder="Age"
          error-message="Please enter a numeric value between 16 and 99."
          @update:is-valid="(isValid) => (isNumberValid = isValid)"
        />
        <p>
          We ask your age to create your plan. <br />
          Older people tend to have more body fat than younger people with the
          same BMI.
        </p>
        <BaseButton
          :disabled="!isNumberValid"
          @click="saveAndNavigate('your_age', dataToSend, '/Fitness-Level')"
          >CONTINUE</BaseButton
        >
      </div>
      <img
        :src="
          isFemale
            ? require('../assets/images/sideAgeW.png')
            : require('../assets/images/sideAge.png')
        "
        alt="Activity"
        class="side-image"
      />
    </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import BaseButton from "../components/BaseButton.vue";
import BaseInfo from "../components/BaseInfo.vue";
import BaseNumberBox from "../components/BaseNumberBox.vue";

guardNoCookie();
useProgress().set(23);

const isKg = computed(() => store.state.weight_unit === "kg");
const numberInput = ref();
const isNumberValid = ref(false);
const numberInputEl = ref();
const loading = ref(true);
const perc_reduce = ref("");
const isFemale = computed(() => store.state.gender === "female");
(() => {
  loading.value = true;
  axios
    .get(`/api/VarStore/Session?sid=${store.state.uid}`)
    .then((response) => {
      let formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });

      perc_reduce.value = formatter.format(response.data?.perc_reduce);

      loading.value = false;
    })
    .catch((error) => {
      console.error("Došlo k chybě při načítání dat:", error);
      loading.value = false;
    });
})();

const dataToSend = computed(() => {
  store.state.age = numberInput.value;
  return `${numberInput.value}`;
});

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);
  if (store.state.age !== "") {
    numberInput.value = store.state.age;
  }
});
</script>
<style scoped>
.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.side-image {
  width: 200px;
  position: absolute;
  right: 220px;
  top: 670px;
  @media (max-width: 1200px) {
    display: none;
  }
}
</style>
