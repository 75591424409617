import { createApp } from "vue";
import App from "./AppWizz.vue";
import { router } from "./router";
import store from "./store";
import VueApexCharts from "vue3-apexcharts"

const app = createApp(App);
app.use(VueApexCharts)
app.use(store);
app.use(router);
app.mount("#app");

// Track page views with Google Analytics
router.afterEach((to) => {
  // Ensure gtag is defined
  document.title = to.meta.title || 'Mind-Yoga.App';
  if (typeof gtag === 'function') {
    gtag('config', 'G-8KNF31W3M9', {
      'page_path': to.path,
      'page_title': document.title, // Optionally track the page title
    });
  }
});
