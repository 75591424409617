import { initFacebookPixel, trackPageView } from "@/utils/useFacebookPixel";
import { createRouter, createWebHistory } from "vue-router";
import { getToken } from "./utils/useAuth";
import BillingPage from "./views/BillingPage.vue";
import LoadingPage from "./views/LoadingPage.vue";
import LoginPage from "./views/LoginPage.vue";
import LogoutPage from "./views/LogoutPage.vue";
import PrivacyPolicyPage from "./views/PrivacyPolicyPage.vue";
import RefundPolicyPage from "./views/RefundPolicyPage.vue";
import RegistrationPage from "./views/RegistrationPage.vue";
import ResetPasswordPage from "./views/ResetPasswordPage.vue";
import S00_SelectGender from "./views/S00_SelectGender.vue";
import S01_SelectAge from "./views/S01_SelectAge.vue";
import S01_SelectAgeStart from "./views/S01_SelectAgeStart.vue";
import S03_SelectGoals from "./views/S03_SelectGoals.vue";
import S04_FamiliarWithYoga from "./views/S04_FamiliarWithYoga.vue";
import S04_FamiliarWithCali from "./views/S04_FamiliarWithCali.vue";
import S05_BodyTypeHave from "./views/S05_BodyTypeHave.vue";
import S06_BodyTypeWant from "./views/S06_BodyTypeWant.vue";
import S07_TargetZones from "./views/S07_TargetZones.vue";
import S08_HappyWithBody from "./views/S08_HappyWithBody.vue";
import S09_ActivityLevel from "./views/S09_ActivityLevel.vue";
import S10_TimeWalking from "./views/S10_TimeWalking.vue";
import S11_PushUpsDo from "./views/S11_PushUpsDo.vue";
import S12_YogaLevel from "./views/S12_YogaLevel.vue";
import S12_CaliLevel from "./views/S12_CaliLevel.vue";
import S13_TouchFloor from "./views/S13_TouchFloor.vue";
import S14_ChooseFocus from "./views/S14_ChooseFocus.vue";
import S15_FeelBetweenMeals from "./views/S15_FeelBetweenMeals.vue";
import S16_HowMuchSleep from "./views/S16_HowMuchSleep.vue";
import S17_WaterEveryDay from "./views/S17_WaterEveryDay.vue";
import S18_FollowDiets from "./views/S18_FollowDiets.vue";
// import S19_DietVsYoga from "./views/S19_DietVsYoga.vue";
// import S19_1_FamiliarWithMedication from "./views/S19_1_FamiliarWithMedication.vue";
// import S19_2a_InterestedInLearingMore from "./views/S19_2a_InterestedInLearingMore.vue";
// import S19_2b_TakenMedication from "./views/S19_2b_TakenMedication.vue";
// import S19_3a_WhatDosageWegovy from "./views/S19_3a_WhatDosageWegovy.vue";
// import S19_3b_WhatDosageOzempic from "./views/S19_3b_WhatDosageOzempic.vue";
// import S19_3c_WhatDosageSaxenda from "./views/S19_3c_WhatDosageSaxenda.vue";
// import S19_3d_WhatDosageRybelsus from "./views/S19_3d_WhatDosageRybelsus.vue";
// import S19_3e_WhatDosageMounjaro from "./views/S19_3e_WhatDosageMounjaro.vue";
// import S19_3f_WhatDosageMysimba from "./views/S19_3f_WhatDosageMysimba.vue";
// import S19_3g_HowMuch from "./views/S19_3g_HowMuch.vue";
// import S19_3h_InsuranceCover from "./views/S19_3h_InsuranceCover.vue";
// import S19_4_CurrentlyTaking from "./views/S19_4_CurrentlyTaking.vue";
// import S19_5_StopTaking from "./views/S19_5_StopTaking.vue";
// import S19_6_WhyStopTaking from "./views/S19_6_WhyStopTaking.vue";
import S20_HowTall from "./views/S20_HowTall.vue";
import S21_CurrentWeight from "./views/S21_CurrentWeight.vue";
import S22_PerfectWeight from "./views/S22_PerfectWeight.vue";
import S23_YourAge from "./views/S23_YourAge.vue";
import S24_FitnessLevel from "./views/S24_FitnessLevel.vue";
import S25_SpecialOccasion from "./views/S25_SpecialOccasion.vue";
import S26_YourEvent from "./views/S26_YourEvent.vue";
import S27_OneOnlyPlan from "./views/S27_OneOnlyPlan.vue";
import S28_CreatingPlan from "./views/S28_CreatingPlan.vue";
import S29_EnterEmail from "./views/S29_EnterEmail.vue";
import S30_EmailWithTips from "./views/S30_EmailWithTips.vue";
import S31_PlanIllustration from "./views/S31_PlanIllustration.vue";
import S32_PlanOffer from "./views/S32_PlanOffer.vue";
import S33_PlanPurchase from "./views/S33_PlanPurchase.vue";
import S34_ThankYou from "./views/S34_ThankYou.vue";
import SetPasswordPage from "./views/SetPasswordPage.vue";
import SupportTicketPage from "./views/SupportTicketPage.vue";
import TosPage from "./views/TosPage.vue";
import { ref } from "vue";

const appDomain = ref(process.env.VUE_APP_DOMAIN);

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: S00_SelectGender,
      meta: { title: `${appDomain.value} - select your gender` },
    },
    {
      path: "/Select-Your-Gender",
      component: S00_SelectGender,
      meta: { title: `${appDomain.value} - select your gender` },
    },
    {
      path: "/Select-Your-Age",
      component: S01_SelectAge,
      meta: { title: `${appDomain.value} - select your age group` },
    },
    {
      path: "/Select-Your-Age-Start",
      component: S01_SelectAgeStart,
      meta: { title: `${appDomain.value} - select your age group` },
    },
    {
      path: "/Select-Goals",
      component: S03_SelectGoals,
      meta: { title: `${appDomain.value} - what do you want to achieve?` },
    },
    {
      path: "/Familiar-With-Yoga",
      component: S04_FamiliarWithYoga,
      meta: { title: `${appDomain.value} - are you familiar with yoga?` },
    },
    {
      path: "/Familiar-With-Calisthenics",
      component: S04_FamiliarWithCali,
      meta: {
        title: `${appDomain.value} - are you familiar with calisthenics?`,
      },
    },
    {
      path: "/Body-Type-Have",
      component: S05_BodyTypeHave,
      meta: { title: `${appDomain.value} - choose your current body type` },
    },
    {
      path: "/Body-Type-Want",
      component: S06_BodyTypeWant,
      meta: {
        title: `${appDomain.value} - choose the body type you want to have`,
      },
    },
    {
      path: "/Target-Zones",
      component: S07_TargetZones,
      meta: { title: `${appDomain.value} - choose your target zones` },
    },
    {
      path: "/Happy-With-Body",
      component: S08_HappyWithBody,
      meta: { title: `${appDomain.value} - last time happy with your body` },
    },
    {
      path: "/Activity-Level",
      component: S09_ActivityLevel,
      meta: { title: `${appDomain.value} - How often do you exercise?` },
    },
    {
      path: "/Time-Walking",
      component: S10_TimeWalking,
      meta: {
        title: `${appDomain.value} - how much time do you spend walking?`,
      },
    },
    {
      path: "/Push-Ups-Do",
      component: S11_PushUpsDo,
      meta: { title: `${appDomain.value} - how many push-ups can you do?` },
    },
    {
      path: "/Yoga-Level",
      component: S12_YogaLevel,
      meta: { title: `${appDomain.value} - what is your yoga level?` },
    },
    {
      path: "/Calisthenics-Level",
      component: S12_CaliLevel,
      meta: { title: `${appDomain.value} - what is your calisthenics level?` },
    },
    {
      path: "/Touch-The-Floor",
      component: S13_TouchFloor,
      meta: { title: `${appDomain.value} - can you touch the floor?` },
    },
    {
      path: "/Choose-Focus",
      component: S14_ChooseFocus,
      meta: { title: `${appDomain.value} - choose your focus` },
    },
    {
      path: "/Feel-Between-Meals",
      component: S15_FeelBetweenMeals,
      meta: { title: `${appDomain.value} - how do you feel between meals?` },
    },
    {
      path: "/How-Much-Sleep",
      component: S16_HowMuchSleep,
      meta: { title: `${appDomain.value} - how much sleep do you get?` },
    },
    {
      path: "/Water-Every-Day",
      component: S17_WaterEveryDay,
      meta: { title: `${appDomain.value} - how much water do you drink?` },
    },
    {
      path: "/Follow-Diets",
      component: S18_FollowDiets,
      meta: { title: `${appDomain.value} - do you follow any of these diets?` },
    },
    // {
    //   path: "/Familiar-With-Medication",
    //   component: S19_1_FamiliarWithMedication,
    //   meta: {
    //     title: `${appDomain.value} - have you ever tried weight loss medication?`,
    //   },
    // },
    // {
    //   path: "/Interested-In-Learning",
    //   component: S19_2a_InterestedInLearingMore,
    //   meta: {
    //     title: `${appDomain.value} - would you be interested in learning more?`,
    //   },
    // },
    // {
    //   path: "/Taken-Medication",
    //   component: S19_2b_TakenMedication,
    //   meta: {
    //     title: `${appDomain.value} - have you taken any of these medications?`,
    //   },
    // },
    // {
    //   path: "/What-Dosage-Wegovy",
    //   component: S19_3a_WhatDosageWegovy,
    //   meta: {
    //     title: `${appDomain.value} - what dosage are you currently taking?`,
    //   },
    // },
    // {
    //   path: "/What-Dosage-Ozempic",
    //   component: S19_3b_WhatDosageOzempic,
    //   meta: {
    //     title: `${appDomain.value} - what dosage are you currently taking?`,
    //   },
    // },
    // {
    //   path: "/What-Dosage-Saxenda",
    //   component: S19_3c_WhatDosageSaxenda,
    //   meta: {
    //     title: `${appDomain.value} - what dosage are you currently taking?`,
    //   },
    // },
    // {
    //   path: "/What-Dosage-Rybelsus",
    //   component: S19_3d_WhatDosageRybelsus,
    //   meta: {
    //     title: `${appDomain.value} - what dosage are you currently taking?`,
    //   },
    // },
    // {
    //   path: "/What-Dosage-Mounjaro",
    //   component: S19_3e_WhatDosageMounjaro,
    //   meta: {
    //     title: `${appDomain.value} - what dosage are you currently taking?`,
    //   },
    // },
    // {
    //   path: "/What-Dosage-Mysimba",
    //   component: S19_3f_WhatDosageMysimba,
    //   meta: {
    //     title: `${appDomain.value} - what dosage are you currently taking?`,
    //   },
    // },
    // {
    //   path: "/How-Much",
    //   component: S19_3g_HowMuch,
    //   meta: {
    //     title: `${appDomain.value} - How much were you paying for?`,
    //   },
    // },
    // {
    //   path: "/Insurance-Cover",
    //   component: S19_3h_InsuranceCover,
    //   meta: {
    //     title: `${appDomain.value} - did your health insurance cover a portion of the cost?`,
    //   },
    // },
    // {
    //   path: "/Are-Your-Currently-Taking",
    //   component: S19_4_CurrentlyTaking,
    //   meta: {
    //     title: `${appDomain.value} - are you currently taking weight loss medication?`,
    //   },
    // },
    // {
    //   path: "/How-Long-Ago",
    //   component: S19_5_StopTaking,
    //   meta: {
    //     title: `${appDomain.value} - how long ago did you stop taking weight loss medication?`,
    //   },
    // },
    // {
    //   path: "/Why-stop-taking",
    //   component: S19_6_WhyStopTaking,
    //   meta: {
    //     title: `${appDomain.value} - why did you stop taking weight loss medication?`,
    //   },
    // },
    // {
    //   path: "/Diet-vs-Yoga",
    //   component: S19_DietVsYoga,
    //   meta: { title: `${appDomain.value} - restrictive diet vs yoga` },
    // },
    {
      path: "/How-Tall-Are-You",
      component: S20_HowTall,
      meta: { title: `${appDomain.value} - how tall are you?` },
    },
    {
      path: "/Current-Weight",
      component: S21_CurrentWeight,
      meta: { title: `${appDomain.value} - what is your current weight?` },
    },
    {
      path: "/Perfect-Weight",
      component: S22_PerfectWeight,
      meta: {
        title: `${appDomain.value} - what would you consider your perfect weight?`,
      },
    },
    {
      path: "/Your-Age",
      component: S23_YourAge,
      meta: { title: `${appDomain.value} - what is your age?` },
    },
    {
      path: "/Fitness-Level",
      component: S24_FitnessLevel,
      meta: { title: `${appDomain.value} - summary of your fitness level` },
    },
    {
      path: "/Special-Occasion",
      component: S25_SpecialOccasion,
      meta: {
        title: `${appDomain.value} - is there a special occasion you want to lose weight for?`,
      },
    },
    {
      path: "/Your-Event",
      component: S26_YourEvent,
      meta: { title: `${appDomain.value} - when's your event?` },
    },
    {
      path: "/One-Only-Plan",
      component: S27_OneOnlyPlan,
      meta: {
        title: `${appDomain.value} - the one and only plan you'll ever need to get in shape`,
      },
    },
    {
      path: "/Creating-Plan",
      component: S28_CreatingPlan,
      meta: { title: `${appDomain.value} - creating your plan` },
    },
    {
      path: "/Enter-Email",
      component: S29_EnterEmail,
      meta: { title: `${appDomain.value} - enter your email` },
    },
    {
      path: "/Email-With-Tips",
      component: S30_EmailWithTips,
      meta: {
        title: `${appDomain.value} - do you want to receive emails with Mind Yoga tips?`,
      },
    },
    {
      path: "/Plan-Illustration",
      component: S31_PlanIllustration,
      meta: {
        title: `${appDomain.value} - presentation of the prepared exercise plan`,
      },
    },
    {
      path: "/Plan-Offer",
      component: S32_PlanOffer,
      meta: { title: `${appDomain.value} - YOUR MIND YOGA PLAN IS READY!` },
    },
    {
      path: "/Plan-Purchase",
      component: S33_PlanPurchase,
      meta: { title: `${appDomain.value} - Checkout` },
    },
    {
      path: "/Thank-You",
      component: S34_ThankYou,
      meta: { title: `${appDomain.value} - Thank You for Choosing Mind Yoga!` },
    },
    {
      path: "/Billing-Page",
      component: BillingPage,
      meta: { title: `${appDomain.value}` },
    },
    {
      path: "/Registration",
      component: RegistrationPage,
      meta: { title: `${appDomain.value}` },
    },
    {
      path: "/Login",
      component: LoginPage,
      meta: { title: `${appDomain.value} - Login` },
    },
    {
      path: "/Logout",
      component: LogoutPage,
      meta: { title: `${appDomain.value}` },
    },
    {
      path: "/Reset-Password",
      component: ResetPasswordPage,
      meta: { title: `${appDomain.value}` },
    },
    {
      path: "/Set-Password",
      component: SetPasswordPage,
      meta: { title: `${appDomain.value}` },
    },
    {
      path: "/Terms-of-Service",
      component: TosPage,
      meta: { title: `${appDomain.value} - Terms of Service` },
    },
    {
      path: "/Privacy-Policy",
      component: PrivacyPolicyPage,
      meta: { title: `${appDomain.value} - Privacy Policy` },
    },
    {
      path: "/Refund-Policy",
      component: RefundPolicyPage,
      meta: { title: `${appDomain.value} - Refund Policy` },
    },
    {
      path: "/Loading",
      component: LoadingPage,
      meta: { title: `${appDomain.value}` },
    },
    {
      path: "/Support-Ticket",
      component: SupportTicketPage,
      meta: { title: `${appDomain.value} - Support` },
    },
  ],
});
initFacebookPixel();
router.beforeEach((to, from, next) => {
  const requiresAuth = to.path === "/Billing-Page";
  const isAuthenticated = getToken();
  trackPageView();
  if (requiresAuth && !isAuthenticated) {
    next({ path: "/Login", query: { redirect: to.fullPath } });
  } else {
    next();
  }
});
