<script setup>
import { computed, ref, watchEffect } from 'vue';
defineProps({
    errorMessage: String,
});

const emit = defineEmits(['update:isValid']);

const today = new Date().toISOString().split('T')[0];

const model = defineModel(); 
const isFocused = ref(false);
const isValid = computed(() => {
    return model.value >= today;
});

const isError = computed(() => {
    if(!model.value || isFocused.value) return false;
    return !isValid.value;
});

watchEffect(() =>{
    emit('update:isValid', isValid.value);
})

</script>

<template>
  <div class="wrapper">
    <input
    v-model="model"
      type="date"
      class="input"
      :min="today"
      :class="{errorInput: isError}"
      @focus="isFocused = true"
       @blur="isFocused = false"
    />
    <p v-if="isError" class="error">{{ errorMessage }}</p>
  </div>
</template>

<style scoped>
.input {
  font-size: 20px;
  font-family: Roboto, sans-serif;
  font-weight: lighter;
  color: #495057;
  width: 300px;
  height: 64px;
  padding: 10px;
  margin: 12px 0;
  border: 2px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;

  &:focus {
    border: 2px solid #4263eb;
    outline: 1px solid #4263eb;
  }
}
.errorInput{
    border: 2px solid red;
    outline: 1px solid red;
}

.error {
    color: red;
    font-size: 16px;
    margin-top: 0;
}
.wrapper {
  margin: 36px 0;
}
</style>
