<template>  
  <div id="step{{ $store.state.step }}" class="survey-step">
    <h2>Checkout</h2>

    <div id="checkout">
      <!-- Checkout inserts the payment form here -->
    </div>

  </div>
</template>
<script setup>
  import { guardNoCookie } from "@/logic/guardNoCookie";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import axios from "axios";
import { onBeforeUnmount, onMounted, ref } from "vue";

  guardNoCookie();
  useProgress().set(33);

  const stripe = ref(null);
  const checkout = ref(null);

  const loadStripe = async () => {
    //console.log('loadStripe');
if (!window.Stripe) {
  //console.log('createElement');
   const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      document.head.appendChild(script);
      await new Promise((resolve) => {
        script.onload = resolve;
      });
    }
    stripe.value = Stripe(store.state.spk);
  };

  const initializeCheckout = async () => {
    //console.log('initializeCheckout');
    // Assuming you've handled any necessary cleanup for previous checkout instances

    // Example payload, replace with your actual data as needed
    const payload = {
      SetupPriceId: '',
      PriceId: '',
      uid: store.state.uid,
    };

    const response = await fetch("/api/Stripe/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const { clientSecret } = await response.json();

    // Initialize checkout with the received clientSecret
    checkout.value = await stripe.value.initEmbeddedCheckout({
      clientSecret,
    });

    checkout.value.mount('#checkout');
  };

  onMounted(async () => {
        updateProgressBar();
    await axios
      .get(`/api/Stripe/get-stripe-public-key`)
      .then((response) => {
        store.state.spk = response.data.toString();
        console.log(`public key:${  store.state.spk}`);
      })
      .catch((error) => {
        console.error("Došlo k chybě při načítání dat:", error);
      });
    await loadStripe();
    await initializeCheckout();


    console.log(`${store.state.step}/${store.state.totalSteps}`);
  });

  onBeforeUnmount(() => {
    // Perform any necessary cleanup here, such as removing the checkout form or resetting component state
    // Note: Ensure you handle this according to Stripe's best practices or API capabilities
    //console.log('onBeforeUnmount');
    checkout.value.unmount();
    checkout.value.destroy();

  });
</script>

