    <script setup>
    import { nextTick, ref } from 'vue';

    const props = defineProps({
    title: String,
    description: String,
    });
    const isOpen = ref(false);
    const contentRef = ref();
    const contentHeight = ref(0);
    async function resolveContentHeight(el) {
    await nextTick();
    contentHeight.value = el.scrollHeight;
    }
    </script>

    <template>
    <div style="width: 100%; max-width: 660px;" @click="isOpen = !isOpen">
    <div class="listItem">
    <div>
    <h3 >
    {{ props.title }}
    </h3>
    </div>
    <img
    height="16px" :class="{ rotate: isOpen }"
    class="arrow" src="../assets/images/right.svg" alt="arrow" />

    </div>
    <Transition
    name="content"
    @after-leave="contentHeight = 0"
    @before-enter="resolveContentHeight"
    @before-leave="resolveContentHeight"
    >
    <div
    v-if="isOpen"
    ref="contentRef"
    class="content"
    >
    <p class="description">{{ description }}</p>
    </div>
    </Transition>
    </div>
    </template>

    <style scoped>
    .listItem {
    padding: 0 12px 0 12px;
    display: flex;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 64px;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
    }
    .arrow {
    margin-left: auto;
    color: #ccc;
    transition: transform 0.2s ease-in-out;

    }
    .rotate {
    transform: rotate(90deg);
    }

    .content {
    height: calc(v-bind(contentHeight) * 1px);
    overflow: hidden;
    }

    .content-enter-from, .content-leave-to {
    height: 0;
    }

    .content-enter-active, .content-leave-active {
    transition-property: height;
    transition-duration: 0.3s;
    }

    .description {
    margin-top: 0;
    max-width: 660px;
    font-size: 16px;
    }
    </style>