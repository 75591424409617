<template>
  <div id="step{{ $store.state.step }}" class="survey-step">
    <div style="display: flex; justify-content: center; width: 100%">
      <div class="options">
        <h2>How tall are you?</h2>
        <BaseSwitch
          v-model="unit"
          first-value="in"
          second-value="cm"
          first-text="In"
          second-text="Cm"
        />
        <BaseNumberBox
          v-if="unit === 'cm'"
          v-model="numberCm"
          :min="50"
          :max="250"
          placeholder="Height in centimeters"
          error-message="Please enter a numeric value between 50 and 250."
          @update:is-valid="(isValid) => (isCmValid = isValid)"
        />
        <div
          v-else
          style="
            display: flex;
            gap: 20px;
            margin: 24px 0;
            flex-wrap: wrap;
            justify-content: center;
          "
        >
          <BaseNumberBox
            v-model="numberFeet"
            style="margin: 0"
            :min="3"
            :max="10"
            placeholder="Feet"
            error-message="Please enter a numeric value between 3 and 10."
            @update:is-valid="(isValid) => (isFeetValid = isValid)"
          />
          <BaseNumberBox
            v-model="numberInches"
            style="margin: 0"
            :min="0"
            :max="11"
            placeholder="Inches"
            error-message="Please enter a numeric value between 0 and 11."
            @update:is-valid="(isValid) => (isInchesValid = isValid)"
          />
        </div>
        <p>Calculating your body mass index</p>
        <p style="font-size: 14px">
          BMI is widely used as a risk factor for the development of or the
          prevalence of several health issues.
        </p>
        <BaseButton
          :disabled="!isNumberValid"
          @click="
            {
              saveAndNavigate('height', dataToSend, '/Current-Weight');
            }
          "
          >CONTINUE</BaseButton
        >
      </div>
      <img
        :src="
          isFemale
            ? require('../assets/images/sideActivityW.png')
            : require('../assets/images/sideActivity.png')
        "
        alt="Activity"
        class="side-image"
      />
    </div>
  </div>
</template>

<script setup>
import { guardNoCookie } from "@/logic/guardNoCookie";
import { saveAndNavigate } from "@/logic/saveAndNavigate";
import { updateProgressBar } from "@/logic/updateProgressBar";
import { useProgress } from "@/logic/useProgress";
import store from "@/store";
import { computed, onMounted, ref } from "vue";
import BaseButton from "../components/BaseButton.vue";
import BaseNumberBox from "../components/BaseNumberBox.vue";
import BaseSwitch from "../components/BaseSwitch.vue";

guardNoCookie();
useProgress().set(20);

const numberFeet = ref();
const isFeetValid = ref(false);

const numberInches = ref();
const isInchesValid = ref(false);

const numberCm = ref();
const isCmValid = ref(false);

const isFemale = computed(() => store.state.gender === "female");

const numberInput = computed(() => {
  if (unit.value === "in") {
    return numberFeet.value * 12 + numberInches.value;
  }
  return numberCm.value;
});
const unit = ref("cm"); //in

if (
  store.state.country === "US" ||
  store.state.country === "GB" ||
  store.state.country === ""
)
  unit.value = "in";

const isNumberValid = computed(() => {
  if (unit.value === "in") {
    return isFeetValid.value && isInchesValid.value;
  }
  return isCmValid.value;
});
const dataToSend = computed(() => {
  store.state.height = numberInput.value;
  store.state.height_unit = unit.value;
  return `${numberInput.value} ${unit.value}`;
});

onMounted(() => {
  updateProgressBar();
  console.log(`${store.state.step}/${store.state.totalSteps}`);

  if (store.state.height !== "") {
    unit.value = store.state.height_unit;
    if (unit.value === "in") {
      numberFeet.value = Math.floor(store.state.height / 12);
      numberInches.value = store.state.height % 12;
    } else {
      numberCm.value = store.state.height;
    }
  }
});
</script>
<style scoped>
.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*
.side-image {
  width: 300px;
  position: absolute;
  right: -360px;
  @media (max-width: 1200px) {
    display: none;
  }
}
*/
.side-image {
  width: 200px;
  position: absolute;
  right: 220px;
  top: 720px;
  @media (max-width: 1200px) {
    display: none;
  }
}
</style>
