<script setup>
import { useProgress } from "@/logic/useProgress";
import { computed } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
function goBack() {
  router.go(-1);
}

const isShown = computed(() => {
  const currentStep = useProgress().currentStep;
  return (
    (currentStep > 2 || currentStep === 1) &&
    currentStep !== 33 &&
    currentStep !== 32
  );
});
</script>

<template>
  <div v-show="isShown" class="top-bar">
    <div class="head">
      <img
        src="../assets/images/back.svg"
        alt="Back"
        class="back-button"
        @click="goBack"
      />
      <img src="../assets/images/logo.png" alt="logo" width="80px" />
      <!-- <p>
        {{ useProgress().currentStep
        }}<span class="gray-text"> /{{ useProgress().totalSteps }}</span>
      </p> -->
    </div>
    <div id="progressBarContainer">
      <div id="progressBar" />
    </div>
  </div>
</template>

<style scoped>
.back-button {
  position: absolute;
  left: 0;
  margin-left: 6px;
  cursor: pointer;
  height: 24px;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    transform: scale(0.85);
  }
}
.head {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.head p {
  position: absolute;
  right: 0;
  margin-right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.top-bar {
  margin-bottom: 32px;
  @media screen and (max-width: 768px) {
    margin-bottom: 24px;
  }
}

.gray-text {
  letter-spacing: 2px;
  color: #adb5bd;
}
</style>
